@if (open) {
  <kendo-dialog
    title="Share link"
    [themeColor]="dialogThemeColor"
    [height]="dialogHeight"
    [width]="dialogWidth"
    [maxWidth]="dialogMaxWidth"
    (close)="close()">

    <p class="share-file">{{ link.metadataStorageName }}</p>
    <!-- <p class="share-link">{{ link.metadataStoragePath }}</p> -->

    <form [formGroup]="shareForm" (ngSubmit)="onSubmitShare()">
      <div class="input-spacer">
        <kendo-formfield class="form-input">
          <kendo-label
            labelCssClass="k-form-label"
            [for]="email"
            text="Email to:"></kendo-label>
          <kendo-textbox
            formControlName="email"
            #email
            showErrorIcon="initial"
            required></kendo-textbox>

          @if (shareForm.get('email')?.invalid && shareForm.get('email')?.errors?.['required']) {
            <kendo-formerror>Email is required</kendo-formerror>
          }
          @if (shareForm.get('email')?.invalid && shareForm.get('email')?.errors?.['email']) {
            <kendo-formerror>Email is not valid</kendo-formerror>
          }
        </kendo-formfield>
      </div>

      <div class="input-spacer">
        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="emailSubject"
            text="Subject:"></kendo-label>
          <kendo-textbox
            formControlName="emailSubject"
            #emailSubject></kendo-textbox>
        </kendo-formfield>
      </div>

      <kendo-formfield class="form-input">
        <kendo-label
          labelCssClass="k-form-label"
          [for]="emailBody"
          text="Message:"></kendo-label>
        <kendo-textarea formControlName="emailBody" #emailBody></kendo-textarea>
        <kendo-formhint>Optional message to send with the link.</kendo-formhint>
      </kendo-formfield>

      <div class="k-actions k-actions-end btn-actions" kendoRippleContainer>
        <button kendoButton type="button" (click)="close()">Cancel</button>
        <button kendoButton themeColor="primary" type="submit">
          Share link
        </button>
      </div>
    </form>
  </kendo-dialog>
}
