import {Component, Inject, OnDestroy, OnInit, inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {AuthService} from './auth.service';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LabelModule} from '@progress/kendo-angular-label';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {Router} from '@angular/router';
import {SVGIcon, exclamationCircleIcon} from '@progress/kendo-svg-icons';
import {IconsModule} from '@progress/kendo-angular-icons';
import {RippleModule} from '@progress/kendo-angular-ripple';
import {SpinnerService} from '../../shared/spinner/spinner.service';
import {ApiService} from '../../services/api.service';
import {AlertService} from '../../shared/alert/alert.service';
import {AlertEnum} from '../../shared/alert/alert.enum';
import {RegistrationService} from '../registration/services/registration.service';
import {ProfileService} from '../user-profile/services/profile.service';
import {convertToBoolean} from '../../utilities/formating';
import {MatIcon} from '@angular/material/icon';
import {MatButtonModule} from "@angular/material/button";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { Subject, filter, takeUntil } from 'rxjs';
import { RoutePath } from '../../enums/api.enum';


@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    NgOptimizedImage,
    IconsModule,
    RippleModule,
    MatIcon,
    MatButtonModule
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit, OnDestroy {
  // authForm: FormGroup;
  // showErrorMessage = false;
  isIframe = false;

  // protected readonly exclamationCircleIcon: SVGIcon = exclamationCircleIcon;
  private readonly _destroying$ = new Subject<void>();

  // #formBuilder = inject(FormBuilder);
  // #authService = inject(AuthService);
  // #router = inject(Router);
  // #spinnerService = inject(SpinnerService);
  // #apiService = inject(ApiService);
  // #alertService = inject(AlertService);
  // #registrationService = inject(RegistrationService);
  // #profileService = inject(ProfileService);
  #msalService = inject(MsalService);
  #msalBroadcastService = inject(MsalBroadcastService);

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
    // this.authForm = this.#formBuilder.group({
    //   username: new FormControl('', [Validators.required]),
    //   password: new FormControl('', [Validators.required])
    // });
  }

  ngOnInit(): void {
    this.#msalService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener;

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.#msalService.instance.enableAccountStorageEvents();

    this.#msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          console.log('AuthComponent.msalSubject.EventMessage', msg);
          return msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED}),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this.#msalService.instance.getAllAccounts().length === 0) {
          console.log('AuthComponent.msalSubject getAllAccounts().legnth ==0');
          window.location.pathname = `${RoutePath.Feed}`;
        } else {
          console.log('AuthComponent.msalSubject getAllAccounts().legnth !=0');
          window.location.pathname = `${RoutePath.Home}`;
        }
      });
    
    // this.#msalBroadcastService.inProgress$
    //   .pipe(
    //     filter((status: InteractionStatus) => {
    //       console.log('AuthComponent.inProgress.status', status);
    //       return status === InteractionStatus.None}),
    //     takeUntil(this._destroying$)
    //   )
    //   .subscribe((s) => {
    //     this.checkAndSetActiveAccount();
    //   });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onSignin() {
    //  take the authRequest set in the guard configurations into account.
    if (this.msalGuardConfig.authRequest){
      this.#msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.#msalService.loginRedirect();
    }
  }

  onSignOut(): void {
    // localStorage.removeItem('token');
    // this.#authService.isAuthenticated.set(false);
    // this.#router.navigate([`/${RoutePath.SignIn}`]);
    // this.#msalService.logoutRedirect();

    this.#msalService.logoutPopup({
      mainWindowRedirectUri: `${RoutePath.SignIn}`
    });

  }

  // checkAndSetActiveAccount() {
  //   console.log('AuthComponent.checkAndSetActiveAccount');
  //   /**
  //    * If no active account set but there are accounts signed in, sets first account to active account
  //    * To use active account set here, subscribe to inProgress$ first in your component
  //    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
  //    */
  //   let activeAccount = this.#msalService.instance.getActiveAccount();

  //   if (!activeAccount && this.#msalService.instance.getAllAccounts().length > 0) {
  //     let accounts = this.#msalService.instance.getAllAccounts();
  //     this.#msalService.instance.setActiveAccount(accounts[0]);
  //   }
  // }

  // onSubmitAuth(): void {
  //   this.#spinnerService.show();
  //   this.showErrorMessage = false;
  //   if (this.authForm.valid) {
  //     this.#authService.signIn(this.authForm.value).subscribe({
  //       next: resp => {
  //         if (resp.result) {
  //           this.#profileService.userProfile.set({
  //             firstName: resp.firstName,
  //             lastName: resp.lastName,
  //             email: resp.email,
  //             id: resp.id,
  //             language: navigator.language,
  //             businessUnitId: resp.businessUnitId
  //           });

  //           this.#registrationService.isFirstTimeAccess = convertToBoolean(resp.firstTimeUser);

  //           this.#apiService.apiBaseUrl = resp.apiUrl;
  //           this.#apiService.apiKey = resp.apiKey;
  //           this.#authService.isAuthenticated.set(true);
  //           this.#router.navigateByUrl('/');
  //         } else {
  //           this.showErrorMessage = true
  //           console.error('Sign in error.  Bad credentials.');
  //         }
  //         this.#spinnerService.hide();
  //       },
  //       error: err => {
  //         this.#spinnerService.hide();
  //         this.#alertService.showAlert({
  //           message: err.message,
  //           type: AlertEnum.error,
  //         });
  //       }
  //     });
  //   } else {
  //     this.authForm.markAllAsTouched();
  //     this.#spinnerService.hide();
  //     return;
  //   }
  // }
}
