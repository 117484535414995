<div class="feed-wrapper" [ngClass]="{'search-container-sm': isSmallViewPort}">
  <div class="feed-header" [ngClass]="{'feed-header-sm': isSmallViewPort}">
    <h1>Your News Feed</h1>
    <h2>
      Welcome to your news feed page where you can see a custom search based on your interests. &nbsp;&nbsp;<a [routerLink]="searchLink" (click)="setSearchMenuItem()">Click here to search for something else.</a>
    </h2>
  </div>

  @if (searchResults.length > 0) {
    <app-search-result-list [searchList]="searchResults" (doRefreshFeed)="doRefreshFeed($event)"
                            [isFeedPage]="true"></app-search-result-list>
  }
  @if (noResultsFound) {
    <p class="empty">No feeds found from your interests.</p>
  }
</div>

@if (openShareLinkDialog) {
  <app-share-item></app-share-item>
}
@if (openPostDialog) {
  <add-post-dialog></add-post-dialog>
}

