import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {environment} from "../../environments/environment";
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from "@azure/msal-angular";
import { RoutePath } from "../enums/api.enum";

/**
 * Logger used by MSAL that outputs logs in the console.
 * @param logLevel 
 * @param message 
 */
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('LOG:', message);
}

/**
 * Configuration to instantiate MSAL
 * @returns IPublicClientApplication
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  console.log('MSALInstanceFactory');
  return new PublicClientApplication({
    auth: {
      // Application (client) ID from the app registration
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: `/${RoutePath.SignIn}`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

/**
 * MSAL Interceptor is required to request access tokens in order to access the protected resource (Graph)
 * @returns MsalInterceptorConfiguration
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * MSAL Guard is required to protect routes and require authentication before accessing protected routes
 * @returns MsalGuardConfiguration
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: RoutePath.FaildSignIn
  };
}
