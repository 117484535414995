import { Component, effect, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormFieldModule, InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IInterest } from '../../../interfaces/i-interest';
import { Subscription } from 'rxjs';
import { AlertEnum } from '../../../shared/alert/alert.enum';
import { ShareItemService } from '../../share-item/services/share-item.service';
import { ProfileService } from '../../user-profile/services/profile.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { IShareLink } from '../../../interfaces/i-share-item';
import { MyPostsService } from '../services/my-posts.service';
import { IPostRequestBody } from '../../../interfaces/i-post';
import { Router } from '@angular/router';
import { UiLayoutService } from '../../../ui-layout/services/ui-layout.service';
import { NavItemLabels, RoutePath } from '../../../enums/api.enum';
import { CommonModule } from '@angular/common';
import { convertToOneOrZero } from '../../../utilities/formating';
import { MatButton } from "@angular/material/button";
import { MediaShareComponent } from "../media-share/media-share.component";
import { MatMenuModule } from "@angular/material/menu";
import { SENSITIVITY_LABELS } from "../../../constants/sensitivity.constants";
import { MatIconModule } from "@angular/material/icon";
import { ISensitivityLabel } from "../../../interfaces/i-sensitivity-label";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BreakpointService } from "../../../services/breakpoint.service";
import { FILE_DOWNLOAD_BASE_URL, FILE_DOWNLOAD_KEY, FILE_UPLOAD_URL } from "../../../constants/api.constant";
import { FileInfo, FileRestrictions, UploadsModule, ErrorEvent, SelectEvent, SuccessEvent, UploadEvent } from "@progress/kendo-angular-upload";


@Component({
  selector: 'app-add-post',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    FormFieldModule,
    LabelModule,
    TextBoxModule,
    InputsModule,
    DropDownsModule,
    UploadsModule,
    MatButton,
    MediaShareComponent,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './add-post.component.html',
  styleUrl: './add-post.component.scss'
})
export class AddPostComponent implements OnDestroy, OnInit {
  @Input() showInDialog = false;
  @Output() closeDialogEvent = new EventEmitter(false);

  link!: IShareLink;
  postForm: FormGroup;
  interestDataSource: IInterest[] = [];
  interestData: IInterest[] = [];
  showMediaShare = false;
  isSubmitting = false;
  isRecordingScreenCapture = false;
  isSmallViewport = false;
  sensitivityLabel: ISensitivityLabel = { id: 0, label: '' };
  isSensitivityValid = signal(true);
  // Video share file(s)
  uploadedFiles: FileInfo[] = [];
  // Display the Add screen share video button
  showAddScreenShareButton = false;

  // Kendo UI file upload component
  uploadRemoveUrl = `${FILE_UPLOAD_URL}/fileUpload`;
  uploadSaveUrl = `${FILE_UPLOAD_URL}/fileUpload`;
  fileTypeRestrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'csv', 'mp4', 'ppt', 'pptx', 'xlsx', 'doc', 'docx', 'm4a', 'mov', 'mp3', 'msg', 'pdf', 'txt', 'xls'],
  };
  // END Kendo UI file upload component

  #shareItemService = inject(ShareItemService);
  #formBuilder = inject(FormBuilder);
  #profileService = inject(ProfileService);
  #myPostsService = inject(MyPostsService);
  #uiLayoutService = inject(UiLayoutService);
  #router = inject(Router);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #breakpointService = inject(BreakpointService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.interestDataSource = this.#profileService.allInterests;
    // Clear out any file
    this.#myPostsService.mediaFile = undefined;

    if (this.interestDataSource.length === 0) {
      this.#getInterests();
    } else {
      this.interestData = this.#profileService.allInterests;
    }

    this.postForm = this.#formBuilder.group({
      title: new FormControl('', [Validators.required]),
      post: new FormControl('', [Validators.required]),
      enabled: new FormControl(true),
      keywords: new FormControl(''),
      fileUpload: new FormControl(this.uploadedFiles),
      attachedFiles: new FormControl('')
    });

    // Decide whether to show "Add screen share video" button.
    if (navigator.mediaDevices  && "getDisplayMedia" in navigator.mediaDevices) {
      // The browser supports navigator.getDisplayMedia()
      this.showAddScreenShareButton = true;
    }

    effect(() => {
      this.link = this.#shareItemService.sharedLink();
    });
  }

  ngOnInit() {
    this.isSmallViewport = this.#breakpointService.isSmallViewport();
  }

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Filters the keywords when a user types in the keyword dropdown element.
   * @param searchTerm
   */
  onChangeFilter(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) => item.description!.toLowerCase().includes(value.toLowerCase());

    this.interestData = this.interestDataSource.filter(contains(searchTerm));
  }

  /**
   * Saves a post.
   */
  onSubmitPost() {
    this.#spinnerService.show();
    this.isSubmitting = true;

    // Form is valid
    if (this.postForm.valid && this.sensitivityLabel.id > 0) {
      let requestBody: Partial<IPostRequestBody> = {};
      const name = `${this.#profileService.userProfile().firstName} ${this.#profileService.userProfile().lastName}`;
      // The user's name must be in the keywords by defualt so when a user searches for their name, the posts they created will show up in the results.  Add it here.
      const keywords = [name];
      // Path to the video share on the storage.
      let metadataStoragePath = '';
      // Create file name for the screen share video using date time
      let screenShareFileName = `screen-share_${new Date().toISOString()}.mp4`;
      screenShareFileName = screenShareFileName.replace(':', '-');
      // List of attached files. (Not the video share file)
      let attachedFilesAry = this.postForm.get('attachedFiles')?.value;
      let attachedFiles: string[] = [];

      // Get the list of attached files from the Kendo Ui kendo-upload component
      if (attachedFilesAry && attachedFilesAry.length > 0) {
        for (const file of attachedFilesAry) {
          attachedFiles.push(file['name']);
        }
      }

      // Now add any keywords the user has selected in the keyword dropdown list to the keywords array.  The user's name should already be added.
      if (Array.isArray(this.postForm.get('keywords')?.value)) {
        const keywordValues = this.postForm.get('keywords')?.value;
        const interests = keywordValues.map((item: IInterest) => {
          return item.description;
        });

        keywords.push(...interests);
      }

      // If there is a video share file, create the storage path
      if (this.#myPostsService.mediaFile) {
        metadataStoragePath = `${FILE_DOWNLOAD_BASE_URL}/${screenShareFileName}?${FILE_DOWNLOAD_KEY}`;

        // console.log('toUTCString', `screen-share_${new Date().toUTCString()}.mp4`);
        // console.log('toISOString', `screen-share_${new Date().toISOString()}.mp4`);
        // console.log('toLocaleTimeString', `screen-share_${new Date().toLocaleTimeString()}.mp4`);
        // console.log('toDateString', `screen-share_${new Date().toDateString()}.mp4`);
      }

      // Build the request body to be sent to the addPost()
      requestBody = {
        userId: this.#profileService.userProfile().id,
        title: this.postForm.get('title')?.value,
        post: this.postForm.get('post')?.value,
        enable: convertToOneOrZero(this.postForm.get('enabled')?.value),
        sensitivity: this.sensitivityLabel.id,
        metadata_storage_path: metadataStoragePath,
        files: attachedFiles.join(','),
        keywords
      };

      console.log('addPost', requestBody);

      const subscription = this.#myPostsService.addPost(requestBody).subscribe({
        next: resp => {
          // The response as of now will be {result: false} when there was a problem or {postId: number} when it was successful
          if (Object.hasOwn(resp, 'postId')) {
            // If there's a media file, then upload it.
            if (this.#myPostsService.mediaFile) {
              const file = new File([this.#myPostsService.mediaFile], screenShareFileName);
              const userId = this.#profileService.userProfile().id;
              const formData = new FormData();
              formData.append('file', file);
              // formData.append('userId', userId.toString());
              // formData.append('postId', resp['postId'].toString());

              const sub = this.#myPostsService.fileUpload(formData).subscribe({
                next: resp => {
                  this.#alertService.showAlert({
                    message: 'Post successfully submitted.',
                    type: AlertEnum.success,
                  });
                  // Clear out the file
                  this.#myPostsService.mediaFile = undefined;
                  const url = `/${RoutePath.MyPosts}`;
                  this.#uiLayoutService.updateNavItems(NavItemLabels.MyPosts);
                  this.#router.navigateByUrl(url);
                  this.#spinnerService.hide();
                  this.isSubmitting = false;
                },
                error: err => {
                  this.#spinnerService.hide();
                  this.isSubmitting = false;
                  this.#alertService.showAlert({
                    message: 'An error occurred while uploading the media file.',
                    type: AlertEnum.error,
                  });
                }
              });

              this.#subscriptions.push(sub);
            } else {
              this.#alertService.showAlert({
                message: 'Post successfully submitted.',
                type: AlertEnum.success,
              });

              const url = `/${RoutePath.MyPosts}`;
              this.#uiLayoutService.updateNavItems(NavItemLabels.MyPosts);
              this.#router.navigateByUrl(url);
              this.#spinnerService.hide();
              this.isSubmitting = false;
              // Clear out any file
              this.#myPostsService.mediaFile = undefined;
            }
          } else {
            this.#spinnerService.hide();
            this.isSubmitting = false;
            this.#alertService.showAlert({
              message: 'An error occurred while saving your post.',
              type: AlertEnum.error,
            });
            // Clear out any file
            this.#myPostsService.mediaFile = undefined;
          }
        },
        error: err => {
          this.#spinnerService.hide();
          this.isSubmitting = false;
          this.#alertService.showAlert({
            message: err.message,
            type: AlertEnum.error,
          });
        }
      });

      this.#subscriptions.push(subscription);
    } else {
      if (this.sensitivityLabel.id === 0) {
        this.isSensitivityValid.set(false);
      }
      this.postForm.markAllAsTouched();
      this.#spinnerService.hide();
      return;
    }
  }

  /**
   * Closes the add post dialog.
   */
  closeDialog() {
    this.closeDialogEvent.emit(true);
  }

  /**
   * Gets the selected sensitivity label.
   * @param option
   */
  onSelectSensitivity(option: number) {
    this.sensitivityLabel = SENSITIVITY_LABELS.find(item => item.id === option)!;
    this.isSensitivityValid.set(true);
  }

  /**
   * Lets the add post component know that the user is in a recording session.
   * @param isRecording
   */
  isRecordingEventEmitted(isRecording: boolean) {
    this.isRecordingScreenCapture = isRecording;
  }

  /**
   * Event that listens from the media share if the user cancels the process.
   * @param show 
   */
  showMedaShareEvent(show: boolean) {
    this.showMediaShare = show;
  }

  // Kendo UI File Upload Component Methods
  error(e: ErrorEvent): void {
    console.log(`file upload error event: ${e.files[0].name}`);
  }

  complete(): void {
    console.log(`file upload complete event`);
  }

  blur(): void {
    console.log(`file upload blur event`);
  }

  focus(): void {
    console.log(`file upload focus event`);
  }

  select(e: SelectEvent): void {
    const that = this;
    const fileNames: any[] = [];

    e.files.forEach((file) => {
      fileNames.push(file.name);

      if (file.validationErrors) {
        console.log('file upload select validation error');
      }

      if (!file.validationErrors) {
        console.log('file upload select files', fileNames);
      }

      // if (!file.validationErrors) {
      //   const reader = new FileReader();

      //   reader.onload = function (ev) {
      //     const image = {
      //       src: ev.target["result"],
      //       uid: file.uid,
      //     };

      //     that.imagePreviews.unshift(image);
      //   };

      //   reader.readAsDataURL(file.rawFile);
      // }
    });

    console.log(`select event: ${fileNames}`);
  }

  success(e: SuccessEvent): void {
    console.log(`file upload success event ${e.files[0].name}`);
  }

  upload(e: UploadEvent): void {
    console.log(`file upload upload event ${e.files[0].name}`);
  }

  valueChange(e: FileInfo[]): void {
    console.log(`file upload valueChange event ${e[0].name}`);
  }
  // END Kendo UI File Upload Component Methods

  /**
   * Gets the available interests/keywords list.
   * @private
   */
  #getInterests() {
    this.#spinnerService.show();

    const subscription = this.#profileService.getInterests('').subscribe({
      next: resp => {
        if (Array.isArray(resp)) {
          this.interestDataSource = resp.sort((a, b) => a.description!.localeCompare(b.description!));
          this.interestData = this.interestDataSource.slice();
        } else {
          this.#alertService.showAlert({
            message: 'Error occurred while getting the keyword list.',
            type: AlertEnum.error,
          });
        }
        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });

    this.#subscriptions.push(subscription);
  }
}
