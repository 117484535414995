<div class="interest-wrapper">
<h1>What are you interested in?</h1>
<h2>Select topics you are interested in to help personalize your search experience.</h2>

<form [formGroup]="interestForm">
  <div class="interest-container">
    <kendo-formfield showHints="always">
      <kendo-label text="Other CGI Federal Business Units" class="label-txt">
        <kendo-multiselect
          formControlName="buInterests"
          [data]="buInterestData"
          [filterable]="true"
          textField="description"
          valueField="id"
          (filterChange)="onChangeFilterBu($event)"></kendo-multiselect>
      </kendo-label>
      <kendo-formhint>Select your interests the list.</kendo-formhint>
    </kendo-formfield>
  </div>

  <div class="interest-container">
    <kendo-formfield showHints="always">
      <kendo-label text="Government Agencies" class="label-txt">
        <kendo-multiselect
          formControlName="govAgenciesInterests"
          [data]="govAgenciesInterestData"
          [filterable]="true"
          textField="description"
          valueField="id"
          (filterChange)="onChangeFilterGovAgencies($event)"></kendo-multiselect>
      </kendo-label>
      <kendo-formhint>Select your interests the list.</kendo-formhint>
    </kendo-formfield>
  </div>

  <div class="interest-container">
    <kendo-formfield showHints="always">
      <kendo-label text="General / Technology" class="label-txt">
        <kendo-multiselect
          formControlName="generalInterests"
          [data]="generalInterestData"
          [filterable]="true"
          textField="description"
          valueField="id"
          (filterChange)="onChangeFilterGeneralInterest($event)"></kendo-multiselect>
      </kendo-label>
      <kendo-formhint>Select your interests the list.</kendo-formhint>
    </kendo-formfield>
  </div>

  <div class="default-interests">
    <h3>Default interests applied to all members.
      <button
        mat-button
        type="button"
        (click)="onToggleShowDefaultList()">
        @if (showDefaultInterestList) {
          Hide
        } @else {
          Show
        }
      </button>
    </h3>
    

    @defer (when defaultInterestsDataSource.length > 0) {
      @if (showDefaultInterestList) {
        <mat-chip-set aria-label="Fish selection">
          @for (item of defaultInterestsDataSource; track item.id) {
            <mat-chip>{{item.description}}</mat-chip>
          }
        </mat-chip-set>
      }
    } @error {
      <p>An error happened while gathering your interests</p>
    }
  </div>

  <div class="k-form-buttons btn-submit" kendoRippleContainer>
    <button kendoButton themeColor="primary" (click)="onSubmitSave()">
      Save
    </button>
  </div>
</form>
</div>