import { Component, effect, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormFieldModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { DialogsModule, DialogThemeColor } from '@progress/kendo-angular-dialog';
import { BreakpointService } from '../../services/breakpoint.service';
import { RowFilterModule } from '@progress/kendo-angular-grid';
import { IShareItemRequestBody, IShareLink } from '../../interfaces/i-share-item';
import { ProfileService } from '../user-profile/services/profile.service';
import { ShareItemService } from './services/share-item.service';
import { Subscription } from 'rxjs';
import { AlertEnum } from '../../shared/alert/alert.enum';
import { AlertService } from '../../shared/alert/alert.service';

@Component({
  selector: 'app-share-item',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormFieldModule,
    LabelModule,
    TextBoxModule,
    ButtonModule,
    RippleModule,
    DialogsModule,
    RowFilterModule
  ],
  templateUrl: './share-item.component.html',
  styleUrl: './share-item.component.scss'
})
export class ShareItemComponent implements OnDestroy {
  link!: IShareLink;
  open = false;
  dialogHeight = 'auto';
  dialogWidth = 'auto';
  dialogMaxWidth = '80%';
  dialogThemeColor: DialogThemeColor = 'primary';
  shareForm: FormGroup;

  #formBuilder = inject(FormBuilder);
  #profileService = inject(ProfileService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #shareItemService = inject(ShareItemService);
  #breakpointService = inject(BreakpointService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.shareForm = this.#formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      link: new FormControl(this.link),
      emailBody: new FormControl(''),
      emailSubject: new FormControl('')
    });

    if (this.#breakpointService.isSmallViewport()) {
      this.dialogHeight = '100%';
      this.dialogWidth = '100%';
      this.dialogMaxWidth = 'unset';
    }

    effect(() => {
      this.open = this.#shareItemService.openShareLinkDialog();
      this.link = this.#shareItemService.sharedLink();

      this.shareForm.get('link')?.setValue(this.link.metadataStoragePath);
      this.shareForm.get('emailSubject')?.setValue(`A link from CGI Social has been sent to you: ${this.link.metadataStorageName}`);
    });
  }

  ngOnDestroy(): void {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSubmitShare() {
    this.#spinnerService.show();

    if (this.shareForm.valid) {
      const requestBody: IShareItemRequestBody = {
        userId: this.#profileService.userProfile().id,
        EmailAddresses: this.#profileService.userProfile().email!,
        EmailBody: this.shareForm.get('emailBody')?.value,
        Subject: this.shareForm.get('emailSubject')?.value,
        metadataStoragePath: this.#shareItemService.sharedLink().metadataStoragePath,
        metadataStorageName: this.#shareItemService.sharedLink().metadataStorageName
      };

      const subscription = this.#shareItemService.shareItem(requestBody).subscribe({
        next: resp => {
          this.#spinnerService.hide();
          this.close();
        },
        error: (err) => {
          this.#alertService.showAlert({
            message: 'An error occurred getting the search results.',
            type: AlertEnum.error,
          });

          this.#spinnerService.hide();
        }
      });

      this.#subscriptions.push(subscription);
    } else {
      this.shareForm.markAllAsTouched();
      this.#spinnerService.hide();
      return;
    }
  }

  close(): void {
    this.open = false;
    this.#shareItemService.openShareLinkDialog.set(false);
  }
}
