<kendo-card class="result-card" width="auto">
  <kendo-card-body class="result-card-body">
    <!-- <div class="result-card-header"> -->
    <!-- <h4 kendoCardTitle class="title">
        @switch (documentExtension) {
          @case ('pdf') {
            <kendo-svgicon [icon]="filePdfIcon" size="large" class="pdf-icon"></kendo-svgicon>
          }
          @case ('doc') {
            <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
          }
          @case ('docx') {
            <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
          }
          @case ('ppt') {
            <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
          }
          @case ('pptx') {
            <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
          }
          @case ('xls') {
            <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
          }
          @case ('xlsx') {
            <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
          }
          @case ('csv') {
            <kendo-svgicon [icon]="fileCsvIcon" size="large" class="default-icon"></kendo-svgicon>
          }
          @case ('text') {
            <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
          }
          @case ('mp3') {
            <kendo-svgicon [icon]="fileVideoIcon" size="large" class="default-icon"></kendo-svgicon>
          }
          @case ('zip') {
            <kendo-svgicon [icon]="fileZipIcon" size="large" class="zip-icon"></kendo-svgicon>
          }
          @case ('json') {
            <kendo-svgicon [icon]="dataJsonIcon" size="large" class="default-icon"></kendo-svgicon>
          }
          @default {
            <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
          }
        }
        <a href="{{item.metadataStoragePath}}" target="_blank">{{ item.metadataStorageName }}</a>
      </h4> -->
    <!-- <div class="sensitivity-label">
        <mat-icon class="material-symbols-outlined">shield</mat-icon> 
        <span><strong>Sensitivity:</strong> {{ item.classification }}</span>
      </div> -->
    <!-- </div> -->

    <h5 kendoCardSubtitle class="subtitle">
      @switch (item.dataCard) {
      @case (dataCardType.Govwin) {
      <card-govwin [item]="item" />
      }
      @case (dataCardType.Post) {
      <card-post [item]="item" />
      }
      @case (dataCardType.Snf) {
      <card-snf [item]="item" />
      }
      }


      <!-- @if (content.length > 0) {
        <app-content [content]="content"/>
      } @else {
        <p></p>
      } -->
    </h5>

    <!-- @if (chipsList.length > 0) {
      <mat-chip-set
        class="chip-list"
        aria-label="Searched items in people, keyphrases and organizations">
        @for (item of chipsList; track item) {
          <mat-chip>{{ item }}</mat-chip>
        }
      </mat-chip-set>
    } -->

    <div>
      <hr kendoCardSeparator />
      <kendo-card-actions layout="end" orientation="horizontal">
        <comment-actions [actionsItem]="actionsItem"></comment-actions>
      </kendo-card-actions>
    </div>
  </kendo-card-body>

</kendo-card>