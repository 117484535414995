<div class="post-list-container" [ngClass]="{'post-list-container-sm': isSmallViewPort}">
  <h1>My Posts</h1>

  @if (listData.length > 0) {
    <div class="result-list-header">
      <kendo-textbox
        class="search-input"
        [ngClass]="{'search-input-lg': isSmallViewPort}"
        placeholder="Search within list..."
        (valueChange)="onFilterSearch($event)">
        <ng-template kendoTextBoxSuffixTemplate [showSeparator]="false">
          <div class="search-icon-wrapper">
            <mat-icon
              aria-hidden="false"
              aria-label="Search"
              fontIcon="search"/>
          </div>
        </ng-template>
      </kendo-textbox>
    </div>

    <kendo-listview
      [kendoListViewBinding]="listData"
      [pageable]="pagerSettings"
      [pageSize]="pageSize"
      containerClass="listview-content">
      <ng-template
        kendoListViewItemTemplate
        let-dataItem="dataItem"
        let-isLast="isLast">
        <app-post-card [myPost]="dataItem"></app-post-card>
      </ng-template>
      <ng-template kendoListViewFooterTemplate>
        <div class="footer-note"></div>
      </ng-template>
    </kendo-listview>
  } @else {
    @if (!fetching()) {
      <div class="empty">
        <p>You have no posts available.</p>
        <a mat-flat-button [routerLink]="addPostRoute" class="link-add-post">Add a post</a>
      </div>
    }
  }
</div>
