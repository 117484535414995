import { Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyPostsService } from '../services/my-posts.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { AlertEnum } from '../../../shared/alert/alert.enum';
import { IPost } from '../../../interfaces/i-post';
import { PostCardComponent } from '../post-card/post-card.component';
import { ProfileService } from '../../user-profile/services/profile.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-view-post',
  standalone: true,
  imports: [
    PostCardComponent,
    MatIcon
  ],
  templateUrl: './view-post.component.html',
  styleUrl: './view-post.component.scss'
})
export class ViewPostComponent implements OnDestroy {
  postId: number;
  myPost!: Partial<IPost>;

  #route = inject(ActivatedRoute);
  #myPostsService = inject(MyPostsService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.postId = this.#route.snapshot.params['id'];

    this.#getPost();
  }

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  #getPost() {
    this.#spinnerService.show();

    const subscription = this.#myPostsService.getPost(this.postId).subscribe({
      next: resp => {
        if (resp.hasOwnProperty('result')) {
          this.#alertService.showAlert({
            message: 'Error occurred while getting the post.',
            type: AlertEnum.error,
          });
        } else {
          this.myPost = {
            ...resp,
            metadataStoragePath: resp.metadata_storage_path
          };
        }

        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });

    this.#subscriptions.push(subscription);
  }
}
