import { Injectable } from '@angular/core';

declare var webkitSpeechRecognition: any;
@Injectable({
  providedIn: 'root'
})
export class SpeechService {
  listen(lang: string): Promise<string> {
    const recognition = new webkitSpeechRecognition();
    recognition.lang = lang;
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.start();

    return new Promise((resolve, reject) => {
      recognition.onresult = (event: any) => {
        resolve(event.results[0][0].transcript);
        recognition.abort();
      };

      recognition.onerror = reject;
    });
  }
}
