import { Component, effect, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { Subscription } from 'rxjs';
import { MyStuffService } from './services/my-stuff.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AlertService } from '../../shared/alert/alert.service';
import { AlertEnum } from '../../shared/alert/alert.enum';
import { IMyStuff } from '../../interfaces/i-my-stuff';
import { convertToBoolean } from '../../utilities/formating';
import { CommonModule } from '@angular/common';
import {
  CommentActionsComponent
} from '../unified-search/search-result-list/result-card/comment-actions/comment-actions.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ResultCardComponent } from '../unified-search/search-result-list/result-card/result-card.component';
import { PagerSettings } from '@progress/kendo-angular-grid/pager/pager-settings';
import {
  arrowRotateCwSmallIcon,
  fileCsvIcon,
  fileExcelIcon,
  filePdfIcon,
  filePptIcon,
  fileTxtIcon,
  fileVideoIcon,
  fileWordIcon,
  fileZipIcon,
  dataJsonIcon,
  SVGIcon
} from '@progress/kendo-svg-icons';
import { CardModule } from '@progress/kendo-angular-layout';
import { ContentComponent } from '../unified-search/search-result-list/content/content.component';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { BreakpointService } from '../../services/breakpoint.service';
import { Event } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { MatIcon } from '@angular/material/icon';
import { MyPostsService } from '../my-posts/services/my-posts.service';
import { AddPostDialogComponent } from '../my-posts/add-post-dialog/add-post-dialog.component';
import { DataCardLabel, DataCardType } from '../../enums/api.enum';

@Component({
  selector: 'app-my-stuff',
  standalone: true,
  imports: [
    CommonModule,
    InputsModule,
    LabelModule,
    RippleModule,
    ReactiveFormsModule,
    CommentActionsComponent,
    ListViewModule,
    ResultCardComponent,
    CardModule,
    ContentComponent,
    MatChipListbox,
    MatChipOption,
    SVGIconModule,
    ButtonModule,
    MatIcon,
    AddPostDialogComponent
  ],
  templateUrl: './my-stuff.component.html',
  styleUrl: './my-stuff.component.scss'
})
export class MyStuffComponent implements OnDestroy, OnInit {
  myStuffForm: FormGroup;
  listData: IMyStuff[] = [];
  openPostDialog = false;

  pagerSettings: PagerSettings = {
    previousNext: false,
    pageSizes: false,
    buttonCount: 9,
  };
  pageSize = 20;
  isSmallViewPort = false;
  dataCardLabel = DataCardLabel;
  dataCardType = DataCardType;
  fetching = signal<boolean>(true);

  protected readonly fileWordIcon: SVGIcon = fileWordIcon;
  protected readonly fileCsvIcon: SVGIcon = fileCsvIcon;
  protected readonly filePptIcon: SVGIcon = filePptIcon;
  protected readonly filePdfIcon: SVGIcon = filePdfIcon;
  protected readonly fileTxtIcon: SVGIcon = fileTxtIcon;
  protected readonly fileVideoIcon: SVGIcon = fileVideoIcon;
  protected readonly fileExcelIcon: SVGIcon = fileExcelIcon;
  protected readonly fileZipIcon: SVGIcon = fileZipIcon;
  protected readonly arrowRotateCwSmallIcon = arrowRotateCwSmallIcon;
  protected readonly dataJsonIcon: SVGIcon = dataJsonIcon;

  #listDataSource: IMyStuff[] = [];
  #formBuilder = inject(FormBuilder);
  #myStuffService = inject(MyStuffService);
  #myPostsService = inject(MyPostsService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #breakpointService = inject(BreakpointService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();

    this.myStuffForm = this.#formBuilder.group({
      bookmarks: new FormControl(true),
      likes: new FormControl(true),
      dislikes: new FormControl(true),
      downloads: new FormControl(true),
    });

    effect(() => {
      this.openPostDialog = this.#myPostsService.openPostDialog();
    });

  }
  
  ngOnInit(): void {
    this.#spinnerService.show();
    setTimeout(() => {
      this.#getMyStuff();
    }, 500);
  }

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onFilterSearch(value: Event): void {
    if (value.toString() !== '') {
      this.listData = process(this.listData, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'metadataStorageName',
              operator: 'contains',
              value: value,
            }
          ],
        },
      }).data;
    } else {
      this.listData = this.#listDataSource.slice();
    }

    // this.dataBinding!.skip = 0;
  }

  onFilterType(): void {
    const list: IMyStuff[] = this.#listDataSource.slice();
    let updatedList: IMyStuff[] = [];
    let bookmarks: IMyStuff[] = [];
    let likes: IMyStuff[] = [];
    let dislikes: IMyStuff[] = [];
    let downloads: IMyStuff[] = [];

    // NOTE: There's a delay for some reason between the event that updates the form and this method is fired off.
    // It seems that this method is fired off first and the form is not updated at that moment. So we have a timer here.
    setTimeout(() => {
      if (this.myStuffForm.get('bookmarks')?.value) {
        bookmarks = list.filter(item => item.bookmark === true);
      }

      if (this.myStuffForm.get('likes')?.value) {
        likes = list.filter(item => item.liked === true);
      }

      if (this.myStuffForm.get('dislikes')?.value) {
        dislikes = list.filter(item => item.disliked === true);
      }

      if (this.myStuffForm.get('downloads')?.value) {
        downloads = list.filter(item => item.downloaded === true);
      }

      updatedList = [...bookmarks, ...likes, ...dislikes, ...downloads];

      this.listData = this.#manageArray(updatedList);
    }, 100);
  }

  actionEventEmitted(actionItem: any) {
    const list = this.listData.slice();

    // First update the action item's properties because there was a change from CommentActionsComponent
    const updatedList = list.map(item => {
      return (item.metadataStorageName === actionItem.metadataStorageName) ? actionItem : item;
    });

    // If all actions are false, find where it is in the array and remove it.
    if (!actionItem.liked && !actionItem.downloaded && !actionItem.emailed && !actionItem.disliked && !actionItem.bookmark) {
      const idx = updatedList.findIndex(item => item.metadataStorageName === actionItem.metadataStorageName);
      updatedList.splice(idx, 1);
    }

    // Now update the list with the new list
    this.listData = updatedList;
  }

  #getMyStuff() {
    // this.#spinnerService.show();

    const subscription = this.#myStuffService.getMyStuff().subscribe({
      next: (resp: any) => {
        if (Array.isArray(resp)) {
          // TODO: Temp code until we get a standard format of an API response
          const keyExists = resp.filter(obj => Object.hasOwn(obj, 'result')).length > 0;

          // The response is [{"result": false}]
          if (keyExists && !resp[0]['result']) {
            console.log('No posts available');
            this.#spinnerService.hide();
            // END Temp code
          } else {
            const updatedList = resp.map(item => {
              return {
                userId: item.userid,
                metadataStorageName: item.metadata_storage_name,
                metadataStoragePath: item.metadata_storage_path,
                liked: convertToBoolean(item.liked),
                notes: item.notes,
                score: item.score,
                emailed: convertToBoolean(item.emailed),
                bookmark: convertToBoolean(item.bookmark),
                disliked: convertToBoolean(item.disliked),
                downloaded: convertToBoolean(item.downloaded),
                documentExtension: this.#getFileExtension(item.metadata_storage_name)
              };
            });

            this.listData = this.#manageArray(updatedList);

            this.#spinnerService.hide();
            this.fetching.set(false);

            this.#listDataSource = this.listData.slice();
          }
        }
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
        this.fetching.set(false);
      }
    });

    this.#subscriptions.push(subscription);
  }

  #getFileExtension(fileName: string): string {
    const file = fileName.split('.');
    return file[file.length - 1];
  }

  /**
   * Remove any duplicates and sort alphabetically
   * @private
   */
  #manageArray(list: IMyStuff[]): IMyStuff[] {
    const updatedList = list.filter((obj, index) => {
      return index === list.findIndex(o => obj.metadataStorageName === o.metadataStorageName);
    });

    return updatedList.sort((a, b) => a.metadataStorageName!.localeCompare(b.metadataStorageName!));
  }
}
