<mat-tab-group dynamicHeight>
  <mat-tab label="Profile">
    <app-profile></app-profile>
  </mat-tab>
  <mat-tab label="Interests">
    <div class="interests">
    <app-interests></app-interests>
    </div>
  </mat-tab>
</mat-tab-group>

