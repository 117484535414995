export class SearchRequestBody {
    search: string;
    // count: boolean;

    // Omit this and the property @search.highlights will not be returned
    highlight: string;

    // queryType: string;

    /*
     Return the selected property(s) listed.
     If empty, it will return the default property 'content'.
     Example: select: 'content, people, organizations, keyphrases'
     */
    // select: string;

    // The *PreTag is an HTML element that the API will wrap around the searched text.
    // highlightPreTag: string;
    // highlightPostTag: string;

    // The number of records to return
    top: number;

    searchFields: string;
    orderby: string;
    filter: string;


    constructor(
        search: string,
        highlight = 'unifiedContent-1,keyphrases',
        searchFields = 'keyphrases,unifiedContent',
        orderby = 'lastupdatedate desc',
        filter = '',
        top = 1000
        // count = true,
        // queryType = 'full',
        // select = '',
        // highlightPreTag = '<mark>',
        // highlightPostTag = '</mark>',
    ) {
        this.search = search;
        this.highlight = highlight;
        this.searchFields = searchFields;
        this.orderby = orderby;
        this.filter = filter;
        this.top = top;
        // this.count = count;
        // this.queryType = queryType;
        // this.select = select;
        // this.highlightPreTag = highlightPreTag;
        // this.highlightPostTag = highlightPostTag;
    }
}

