import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { IInterest } from '../../../interfaces/i-interest';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { AlertEnum } from '../../../shared/alert/alert.enum';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { ProfileService } from '../services/profile.service';
import { InterestType } from '../../../enums/api.enum';
import { Subscription, throwError, zip } from 'rxjs';

@Component({
  selector: 'app-interests',
  standalone: true,
  imports: [
    InputsModule,
    MultiSelectModule,
    DropDownsModule,
    LabelModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    MatChipsModule,
    MatButtonModule
  ],
  templateUrl: './interests.component.html',
  styleUrl: './interests.component.scss'
})
export class InterestsComponent implements OnDestroy, OnInit {
  interestForm: FormGroup;
  showDefaultInterestList = true;

  generalInterestDataSource: IInterest[] = [];
  generalInterestData: IInterest[] = [];
  generalInterestGroupedData: GroupResult[] = [];

  buInterestDataSource: IInterest[] = [];
  buInterestData: IInterest[] = [];
  buInterestGroupedData: GroupResult[] = [];

  govAgenciesInterestDataSource: IInterest[] = [];
  govAgenciesInterestData: IInterest[] = [];
  govAgenciesInterestGroupedData: GroupResult[] = [];

  defaultInterestsDataSource: IInterest[] = [];

  #formBuilder = inject(FormBuilder);
  #profileService = inject(ProfileService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    // this.#getInterests();
    this.interestForm = this.#formBuilder.group({
      generalInterests: [''],
      buInterests: [''],
      govAgenciesInterests: ['']
    });
  }

  ngOnInit(): void {
    this.#getInterests();
  }

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onChangeFilterGeneralInterest(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.generalInterestData = this.generalInterestDataSource.filter(contains(searchTerm));
  }

  onChangeFilterBu(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.buInterestData = this.buInterestDataSource.filter(contains(searchTerm));
  }

  onChangeFilterGovAgencies(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.govAgenciesInterestData = this.govAgenciesInterestDataSource.filter(contains(searchTerm));
  }

  onSubmitSave() {
    this.#spinnerService.show();
    const userInterests: { userId: number, interestId: number }[] = [];
    const selectedInterests = [
      ...this.interestForm.get('buInterests')?.value,
      ...this.interestForm.get('govAgenciesInterests')?.value,
      ...this.interestForm.get('generalInterests')?.value
    ];

    selectedInterests.forEach((item: IInterest) => {
      const interest = {userId: this.#profileService.userProfile().id, interestId: item.id};
      userInterests.push(interest);
    });

    this.#profileService.saveInterests(userInterests).subscribe({
      next: resp => {
        this.#alertService.showAlert({
          message: 'Interests saved.',
          type: AlertEnum.success,
        });
        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });
  }

  onToggleShowDefaultList(): void {
    this.showDefaultInterestList = !this.showDefaultInterestList;
  }

  #getInterests() {
    this.#spinnerService.show();

    let call1 = this.#profileService.getInterests('');
    let call2 = this.#profileService.getUsersInterests();

    const subscription = zip(call1, call2).subscribe({
      next: results => {
        if (Array.isArray(results[1])) {
          const defaultInterests = results[1]; // call2 getUsersInterests

          // Filter out user's interests by category
          const buInterests = defaultInterests.filter(item => item.type === InterestType.CGIFBU);
          const gaInterests = defaultInterests.filter(item => item.type === InterestType.GA);
          const genInterests = defaultInterests.filter(item => item.type === InterestType.GEN);
          const defaultList = defaultInterests.filter(item => item.type === InterestType.DEFAULT);

          if (Array.isArray(results[0])) {
            const userBusinessUnit = results[0].find(item => item.id === this.#profileService.userProfile().businessUnitId);

            if (userBusinessUnit) {
              defaultList.push(userBusinessUnit)
            }
          }

          // Set the values for the form dropdowns
          this.interestForm.get('buInterests')?.setValue(buInterests);
          this.interestForm.get('govAgenciesInterests')?.setValue(gaInterests);
          this.interestForm.get('generalInterests')?.setValue(genInterests);

          // Set the default interests list to be displayed in the chips
          this.defaultInterestsDataSource = defaultList;
        }

        if (Array.isArray(results[0])) {
          this.#sortInterests(results[0]);
        } else {
          this.#alertService.showAlert({
            message: 'Error occurred while getting the interest list.',
            type: AlertEnum.error,
          });
        }

        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err,
          type: AlertEnum.error,
        });
        console.error(err)
      }
    });

    this.#subscriptions.push(subscription);
  }

  #sortInterests(interestList: IInterest[]): void {
    this.buInterestDataSource = interestList.filter(item => item.type === InterestType.CGIFBU);
    this.generalInterestDataSource = interestList.filter(item => item.type === InterestType.GEN);
    this.govAgenciesInterestDataSource = interestList.filter(item => item.type === InterestType.GA);

    // Sort list in alphabetical order
    this.buInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));
    this.generalInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));
    this.govAgenciesInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));


    this.generalInterestData = this.generalInterestDataSource.slice();
    this.generalInterestGroupedData = groupBy(this.generalInterestData, [{field: 'category'}]) as GroupResult[];

    this.buInterestData = this.buInterestDataSource.slice();
    this.buInterestGroupedData = groupBy(this.buInterestData, [{field: 'category'}]) as GroupResult[];

    this.govAgenciesInterestData = this.govAgenciesInterestDataSource.slice();
    this.govAgenciesInterestGroupedData = groupBy(this.govAgenciesInterestData, [{field: 'category'}]) as GroupResult[];
  }
}
