<div class="header-container">
  <span class="source"><span class="label">Source</span> {{dataCardLabel.Snf}}</span>

  <span><span class="label">Last update date</span> 
  @if (item.lastUpdateDate) {
    {{item.lastUpdateDate | date: 'yyyy-MM-dd, h:mm a'}}
  } @else {
    Unavailable
  }
  </span>
</div>

<div class="card-header">
    <div class="title">
        <!-- Show document type icon -->
        @switch (documentExtension) {
        @case ('pdf') {
        <kendo-svgicon [icon]="filePdfIcon" size="large" class="pdf-icon"></kendo-svgicon>
        }
        @case ('doc') {
        <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
        }
        @case ('docx') {
        <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
        }
        @case ('ppt') {
        <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
        }
        @case ('pptx') {
        <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
        }
        @case ('xls') {
        <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
        }
        @case ('xlsx') {
        <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
        }
        @case ('csv') {
        <kendo-svgicon [icon]="fileCsvIcon" size="large" class="default-icon"></kendo-svgicon>
        }
        @case ('text') {
        <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
        }
        @case ('mp3') {
        <kendo-svgicon [icon]="fileVideoIcon" size="large" class="default-icon"></kendo-svgicon>
        }
        @case ('zip') {
        <kendo-svgicon [icon]="fileZipIcon" size="large" class="zip-icon"></kendo-svgicon>
        }
        @case ('json') {
        <kendo-svgicon [icon]="dataJsonIcon" size="large" class="default-icon"></kendo-svgicon>
        }
        @default {
        <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
        }
        }
        <a href="{{item.webUrl}}" target="_blank" class="title">{{ title }}</a> <mat-icon
            class="material-symbols-outlined icon-extnl-link" fontIcon="ungroup" />
    </div>

    <!-- SENSITIVITY LABEL -->
    <div class="sensitivity-label">
        <mat-icon class="material-symbols-outlined" fontIcon="shield" />
        <span><strong>Sensitivity:</strong> {{ sensitivityLabel.label }}</span>
    </div>
    <!-- /END SENSITIVITY LABEL -->
</div>

<div class="card-content">
    <app-content [content]="description" />


    <!-- SHOW KEYWORDS -->
    @if (item.keyphrases!.length > 0) {
        <mat-chip-set
          class="chip-list"
          aria-label="Searched items in people, keyphrases and organizations">
          @for (item of item.keyphrases!; track item) {
            <mat-chip>{{ item }}</mat-chip>
          }
        </mat-chip-set>
      }
      <!-- SHOW KEYWORDS -->
</div>