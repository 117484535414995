import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { SpinnerService } from './shared/spinner/spinner.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    SpinnerComponent,
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  spinnerService = inject(SpinnerService);
  apiService = inject(ApiService);

  #msalService = inject(MsalService);
  #msalBroadcastService = inject(MsalBroadcastService);

  ngOnInit(): void {
    this.#msalBroadcastService.inProgress$
      .pipe(
        // Here we are filtering out the action None.
        filter((status: InteractionStatus) => {
          // console.log('AppComponent.this.msalBroadcastService.inProgress$.InteractionStatus', status);
          return status === InteractionStatus.None
        }),
      )
      .subscribe((status: InteractionStatus) => {
        // console.log('UiLayoutComponent.this.msalBroadcastService.inProgress$.InteractionStatus', status);
        // If there's an account signed in the cache, get it from MSAL.
        let activeAccount = this.#msalService.instance.getActiveAccount();

        // If there's no active accounts, but there are some active accounts in the cache,
        if (!activeAccount && this.#msalService.instance.getAllAccounts().length > 0) {
          // Make the active account the first one in this array.  This will get all the accounts in the cache but they may not be active.  
          // You have to explicitly mark the as active
          activeAccount = this.#msalService.instance.getAllAccounts()[0];
          // Now use the MSAL service and set this as the active account.
          this.#msalService.instance.setActiveAccount(activeAccount);
        }

        // Update isAuthenticated
        this.apiService.isAuthenticated.set(!!activeAccount);
        // console.log('AppComponent.isAuthenticated', this.apiService.isAuthenticated());
      });


    this.#msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) => {
          // console.log('AppComponent.this.msalBroadcastService.msalSubject$.EventMessage', message);
          return message.eventType === EventType.LOGIN_SUCCESS
        }),
      )
      .subscribe((message: EventMessage) => {
        // console.log('AppComponent.this.msalBroadcastService.msalSubject$.EventMessage', message);
        
      });
  }
}
