import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DrawerSelectEvent, LayoutModule } from '@progress/kendo-angular-layout';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AnimationDirection, AnimationType, PopupAnimation, PopupModule } from '@progress/kendo-angular-popup';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { IconsModule } from '@progress/kendo-angular-icons';
import { CalendarModule } from '@progress/kendo-angular-dateinputs';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DrawerComponent } from '@progress/kendo-angular-layout/drawer/drawer.component';
import { USER_SETTINGS } from '../constants/user-settings.constant';
import { DrawerMode } from '@progress/kendo-angular-layout/drawer/types';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  menuIcon,
  SVGIcon
} from '@progress/kendo-svg-icons';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { AuthService } from '../pages/auth/auth.service';
import { IUserProfile } from '../interfaces/i-user-profile';
import { BreakpointService } from '../services/breakpoint.service';
import { NavItemLabels, RoutePath, UserSetting } from '../enums/api.enum';
import { BookmarkService } from '../pages/bookmarks/services/bookmark.service';
import { ProfileService } from '../pages/user-profile/services/profile.service';
import { UiLayoutService } from './services/ui-layout.service';
import { MatIcon } from '@angular/material/icon';
import { AiChatComponent } from '../pages/ai-chat/ai-chat.component';
import { MsalBroadcastService, MsalModule, MsalService } from '@azure/msal-angular';
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { NAV_ITEMS } from '../constants/nav-items.constant';
import { ApiService } from '../services/api.service';
import { AuthComponent } from '../pages/auth/auth.component';
import { convertToBoolean } from '../utilities/formating';
import { RegistrationService } from '../pages/registration/services/registration.service';

@Component({
  selector: 'ui-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    NgOptimizedImage,
    NavigationModule,
    MsalModule,
    CalendarModule,
    IconsModule,
    IndicatorsModule,
    ButtonsModule,
    PopupModule,
    InputsModule,
    LayoutModule,
    RippleModule,
    MatIcon,
    AiChatComponent,
    AuthComponent
  ],
  templateUrl: './ui-layout.component.html',
  styleUrl: './ui-layout.component.scss'
})
export class UiLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('anchor', { static: false }) anchor?: ElementRef<HTMLElement>;
  @ViewChild('drawer') drawer?: DrawerComponent;

  userSettings = USER_SETTINGS;
  userProfile: IUserProfile;

  isSmallViewPort = false;

  // Pop up for menu when in a small viewport
  margin = { horizontal: -46, vertical: 7 };
  showSmallViewportMenu = false;
  popupDuration = 200;
  popupType: AnimationType = 'slide';
  popupDirection: AnimationDirection = 'left';
  // END Pop up for menu when in a small viewport

  // Drawer props
  expanded = false;
  drawerMode: DrawerMode = 'push';
  drawerMini = false;
  // END Drawer props

  // MSAL
  activeUser: string | undefined = "unknown user";
  // END MSAL

  uiLayoutService = inject(UiLayoutService);

  get popupAnimate(): boolean | PopupAnimation {
    return {
      type: this.popupType,
      direction: this.popupDirection,
      duration: this.popupDuration
    }
  }

  apiService = inject(ApiService);
  profileService = inject(ProfileService);
  protected readonly menuIcon: SVGIcon = menuIcon;
  readonly #subject = new Subject<void>();

  #breakpointObserver = inject(BreakpointObserver);
  #breakpointService = inject(BreakpointService);
  #router = inject(Router);
  #authService = inject(AuthService);
  #bookmarkService = inject(BookmarkService);
  #msalService = inject(MsalService);
  #msalBroadcastService = inject(MsalBroadcastService);
  #registrationService = inject(RegistrationService);

  constructor() {
    this.#breakpointObserver.observe([
      // Breakpoints.HandsetLandscape,
      // Breakpoints.HandsetPortrait,
      // Breakpoints.Handset,
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      this.#breakpointService.isSmallViewport.set(result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]);
      this.#breakpointService.isMediumViewport.set(result.breakpoints[Breakpoints.Medium]);
      this.#breakpointService.isLargeViewport.set(result.breakpoints[Breakpoints.XLarge] || result.breakpoints[Breakpoints.Large]);

      this.isSmallViewPort = this.#breakpointService.isSmallViewport();
    });

    this.userProfile = this.profileService.userProfile();
    this.uiLayoutService.navItems.set([]);
  }

  ngOnInit(): void {
    // console.log('UiLayoutComponent.ngOnInit');
    // console.log('UiLayoutComponent.isAuthenticated', this.apiService.isAuthenticated());
    // this.getProfile(environment.apiConfig.uri);

    // this.#msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => {
    //       console.log('UiLayoutComponent.this.#msalBroadcastService.msalSubject$.filter.msg', msg);

    //       return msg.eventType === EventType.LOGIN_SUCCESS
    //     }),
    //   )
    //   .subscribe((result: EventMessage) => {
    //     console.log('UiLayoutComponent', result);
    //     const payload = result.payload as AuthenticationResult;
    //     this.#msalService.instance.setActiveAccount(payload.account);
    //   });

    // Subscribe to the MSAL broadcast service.  This will let us know what action MSAL is currently performing.
    this.#msalBroadcastService.inProgress$
      .pipe(
        // Here we are filtering out the action None.
        filter((status: InteractionStatus) => {
          // console.log('UiLayoutComponent.this.msalBroadcastService.inProgress$.InteractionStatus', status);
          return status === InteractionStatus.None
        }),
        takeUntil(this.#subject)
      )
      .subscribe((status: InteractionStatus) => {
        this.#setAuthenticationStatus();
      });

    this.#msalBroadcastService.inProgress$
      .pipe(
        // Here we are filtering out the action None.
        filter((status: InteractionStatus) => status === InteractionStatus.Startup),
        takeUntil(this.#subject)
      )
      .subscribe((status: InteractionStatus) => {
        // console.log('UiLayoutComponent.this.msalBroadcastService.inProgress$.InteractionStatus', status);
        let url = this.#router.url;
        // Get the current route.  If conditions are met, then use the current route.  This is here in case the user refreshes.  It will keep them on the same page.
        if (this.#router.url !== '/' && this.#router.url !== '/signin') {
          url = this.#router.url;
        }

        if (this.apiService.isAuthenticated()) {
          // this.uiLayoutService.navItems.set(NAV_ITEMS);
          let urlRoute = this.#router.url;
          this.#setNavMenu(urlRoute.substring(1, urlRoute.length));
          this.drawerMini = true;

          if (this.profileService.accountProfile()) {
            this.profileService.setAvatar();

            if (this.profileService.userProfile().id === 0) {
              this.profileService.getProfile({ email: this.profileService.accountProfile()?.mail! }).subscribe({
                next: resp => {
                  if (resp.result) {
                    this.profileService.userProfile.set({
                      id: resp.id,
                      firstName: resp.firstName,
                      lastName: resp.lastName,
                      businessUnitId: resp.businessUnitId,
                      email: resp.email,
                      firstTimeUser: resp.firstTimeUser
                    });

                    this.apiService.apiBaseUrl = resp.apiUrl!;
                    this.apiService.apiKey = resp.apiKey!;
                    this.#registrationService.isFirstTimeAccess = convertToBoolean(resp.firstTimeUser!);
                    if (this.#registrationService.isFirstTimeAccess) {
                      url = RoutePath.Registration;
                    } else {
                      url = RoutePath.Feed;
                    }
                  } else {
                    url = RoutePath.Registration;
                  }
                }
              });
            }
          } else {
            // Get profile data
            this.profileService.getAccount().subscribe({
              next: resp => {
                this.profileService.accountProfile.set({
                  id: resp.id,
                  businessPhones: resp.businessPhones,
                  displayName: resp.displayName,
                  givenName: resp.givenName,
                  jobTitle: resp.jobTitle,
                  mail: resp.mail,
                  mobilePhone: resp.mobilePhone,
                  officeLocation: resp.officeLocation,
                  preferredLanguage: resp.preferredLanguage,
                  surname: resp.surname,
                  userPrincipalName: resp.userPrincipalName
                });
                this.profileService.setAvatar();

                this.profileService.getProfile({ email: resp.mail! }).subscribe({
                  next: resp => {
                    if (resp.result) {
                      this.profileService.userProfile.set({
                        id: resp.id,
                        firstName: resp.firstName,
                        lastName: resp.lastName,
                        businessUnitId: resp.businessUnitId,
                        email: resp.email,
                        firstTimeUser: resp.firstTimeUser
                      });
                      this.apiService.apiBaseUrl = resp.apiUrl!;
                      this.apiService.apiKey = resp.apiKey!;
                      this.#registrationService.isFirstTimeAccess = convertToBoolean(resp.firstTimeUser!);
                      if (this.#registrationService.isFirstTimeAccess) {
                        url = RoutePath.Registration;
                      } else {
                        url = RoutePath.Feed;
                      }
                    } else {
                      url = RoutePath.Registration;
                    }
                  }
                });
              }
            });
          }
          this.#router.navigate([url]);
        }
      });

    this.#msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) => {
          // console.log('UiLayoutComponent.this.msalBroadcastService.msalSubject$.EventMessage', message);
          return message.eventType === EventType.LOGIN_SUCCESS
        }),
        takeUntil(this.#subject)
      )
      .subscribe((message: EventMessage) => {
        // console.log('UiLayoutComponent.this.msalBroadcastService.msalSubject$.EventMessage', message);
        // const authResult = message.payload as AuthenticationResult;
        // console.log('authResult', authResult);
        // this.#msalService.instance.setActiveAccount(authResult.account);
      });
  }

  ngOnDestroy(): void {
    this.#subject.next(undefined);
    this.#subject.complete();
  }

  onToggle(): void {
    this.showSmallViewportMenu = !this.showSmallViewportMenu;
  }

  onToggleNavigate(navItem: string): void {
    this.onToggle();

    this.#manageNavItems(navItem);

    if (navItem === RoutePath.Bookmarks) {
      // Let the application know that the user is in the bookmark page.
      this.#bookmarkService.isBookmark.set(true);
    } else {
      this.#bookmarkService.isBookmark.set(false);
    }

  }

  onSelectNavigate(event: DrawerSelectEvent): void {
    this.#manageNavItems(event.item.text);
    this.#router.navigate([event.item.path]);
  }

  onUserSettingClick(event: { text: string }): void {
    if (event.text === UserSetting.Profile) {
      this.#gotoUserProfile();
    }

    if (event.text === UserSetting.SignOut) {
      this.#signOut();
    }
  }

  #gotoUserProfile(): void {
    this.#router.navigate([`/${RoutePath.UserProfile}`]);
  }

  #signOut(): void {
    // localStorage.removeItem('token');
    this.#authService.isAuthenticated.set(false);
    // this.#router.navigate([`/${RoutePath.SignIn}`]);
    // this.#msalService.logoutRedirect();

    this.#msalService.logoutPopup({
      mainWindowRedirectUri: `${RoutePath.SignIn}`
    });

  }

  #manageNavItems(navItem: string) {
    const menuItems = this.uiLayoutService.navItems().slice();
    const updatedMenuItems = menuItems.map(item => {
      return {
        ...item,
        selected: navItem === item.text
      }
    });

    this.uiLayoutService.navItems.set(updatedMenuItems);
  }

  #setAuthenticationStatus(): void {
    // If there's an account signed in the cache, get it from MSAL.
    let activeAccount = this.#msalService.instance.getActiveAccount();

    // If there's no active accounts, but there are some active accounts in the cache,
    if (!activeAccount && this.#msalService.instance.getAllAccounts().length > 0) {
      // Make the active account the first one in this array.  This will get all the accounts in the cache but they may not be active.  
      // You have to explicitly mark the as active
      activeAccount = this.#msalService.instance.getAllAccounts()[0];
      // Now use the MSAL service and set this as the active account.
      this.#msalService.instance.setActiveAccount(activeAccount);
    }

    // Update isAuthenticated
    this.apiService.isAuthenticated.set(!!activeAccount);
    this.activeUser = activeAccount?.username;

    if (this.apiService.isAuthenticated()) {
      this.profileService.getAccount().subscribe({
        next: resp => {
          this.profileService.accountProfile.set({
            id: resp.id,
            businessPhones: resp.businessPhones,
            displayName: resp.displayName,
            givenName: resp.givenName,
            jobTitle: resp.jobTitle,
            mail: resp.mail,
            mobilePhone: resp.mobilePhone,
            officeLocation: resp.officeLocation,
            preferredLanguage: resp.preferredLanguage,
            surname: resp.surname,
            userPrincipalName: resp.userPrincipalName
          });
          this.profileService.setAvatar();

          this.profileService.getProfile({ email: resp.mail! }).subscribe({
            next: resp => {
              if (resp.result) {
                this.profileService.userProfile.set({
                  id: resp.id,
                  firstName: resp.firstName,
                  lastName: resp.lastName,
                  businessUnitId: resp.businessUnitId,
                  email: resp.email,
                  firstTimeUser: resp.firstTimeUser
                });
                this.apiService.apiBaseUrl = resp.apiUrl!;
                this.apiService.apiKey = resp.apiKey!;
                this.#registrationService.isFirstTimeAccess = convertToBoolean(resp.firstTimeUser!);
                if (this.#registrationService.isFirstTimeAccess) {
                  this.#router.navigate([RoutePath.Registration]);
                } else {
                  this.#router.navigate([RoutePath.Feed]);
                }
              } else {
                this.#router.navigate([RoutePath.Registration]);
              }
            }
          });
          // this.uiLayoutService.navItems.set(NAV_ITEMS);
          let urlRoute = this.#router.url;
          this.#setNavMenu(urlRoute.substring(1, urlRoute.length));
          this.drawerMini = true;
          this.profileService.setAvatar();
          
        }
      });
    }
    // console.log('UiLayoutComponent.isAuthenticated', this.apiService.isAuthenticated());
    // console.log('UiLayoutComponent.activeUser', activeAccount);
  }

  #setNavMenu(url: string): void {
    // First set the default nav items
    this.uiLayoutService.navItems.set(NAV_ITEMS);
    let navItem = NavItemLabels.Feed;

    switch(url) {
      case RoutePath.Search:
        navItem = NavItemLabels.Search;
        break
      case RoutePath.MyStuff:
        navItem = NavItemLabels.MyStuff;
        break
      case RoutePath.MyPosts:
        navItem = NavItemLabels.MyPosts;
        break
      case RoutePath.AddPost:
        navItem = NavItemLabels.AddPost;
        break
      default:
        navItem = NavItemLabels.Feed;
    }

    this.uiLayoutService.updateNavItems(navItem);
  }
}
