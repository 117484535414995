import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
  #domSanitizer = inject(DomSanitizer);

  transform(html: string): SafeHtml {
    return this.#domSanitizer.bypassSecurityTrustHtml(html);
  }

}
