@defer (when myPost) {
  <div class="post-wrapper">
    <app-post-card [myPost]="myPost" [isViewCard]="true"></app-post-card>
  </div>
} @placeholder {
  <p class="error-placeholder">
    <mat-icon class="material-symbols-outlined error-placeholder-icon">
      error
    </mat-icon>
    An error occurred while getting the post.
  </p>
}

