import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  /**
   * Lets the application know if the user entered is their first time. If so, the application will take them to the
   * registration page.
   * @private
   */
  #isFirstTime = false;

  get isFirstTimeAccess() {
    return this.#isFirstTime;
  }

  set isFirstTimeAccess(isFirstIme: boolean) {
    this.#isFirstTime = isFirstIme;
  }
}
