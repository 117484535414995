import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from '@angular/material/divider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { RouterLink } from '@angular/router';

enum ChatRole {
  'system',
  'user',
  'assistant'
}

interface IChatHistory {
  id: string;
  role: ChatRole;
  content: string;
}

@Component({
  selector: 'app-ai-assist',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule
  ],
  templateUrl: './ai-assist.component.html',
  styleUrl: './ai-assist.component.scss'
})
export class AiAssistComponent implements AfterViewInit {
  @ViewChild('chat') docElement!: ElementRef<HTMLElement>;
  @ViewChild('btnFs') btnFs!: ElementRef<HTMLElement>;
  @ViewChild('btnExitFs') btnExitFs!: ElementRef<HTMLElement>;
  
  chatForm: FormGroup;
  chatHistory = signal<IChatHistory[]>([
    {
      id: '1',
      role: ChatRole.system,
      content: 'Hello, how may I assist you.'
    }
  ]);
  chatRole = ChatRole;

  // docElement!: HTMLElement;
  isFullScreen = signal<boolean>(false);
  submitting = signal<boolean>(false);

  #formBuilder = inject(FormBuilder);

  constructor() {
    this.chatForm = this.#formBuilder.group({
      content: ['']
    });

  }

  ngAfterViewInit(): void {
    // this.docElement.nativeElement.addEventListener('fullscreenchange', this.exitHandler);
    // document.addEventListener('fullscreenchange', this.exitHandler);
  }

  toggleFullScreen() {
    this.isFullScreen.set(!this.isFullScreen());
  }

  /**
   * Sets the current component into fullscreen.
   */
  // fullScreen() {
  //   this.docElement.nativeElement.requestFullscreen();
  //   this.isFullScreen.set(true);
  //   document.getElementById('btnFs')?.classList.add('btn-hide');
  //   document.getElementById('btnExitFs')?.classList.remove('btn-hide');
  //   document.getElementById('topContainer')?.classList.add('top-fs');
  // }

  /**
   * Exits the current component from fullscreen.
   */
  // exitFullScreen() {
  //   document.exitFullscreen();
  //   this.isFullScreen.set(false);
  //   document.getElementById('btnFs')?.classList.remove('btn-hide');
  //   document.getElementById('btnExitFs')?.classList.add('btn-hide');
  // }

  /**
   * Event handler when the user presses the ESC key while this component is in fullscreen.
   * @description Some browsers such as Chrome will not allow you to listen to the ESC key press event while in fullscreen mode.  This is a hack to listen to the exitFullscreen event instead.  At this point in time we are at the document level and cannot listen or see the application variables.  Therefore, we need to manually do some DOM manipulation.
   * 
   */
  // exitHandler() {
  //   if (!document.fullscreenElement) {
  //     document.getElementById('btnFs')?.classList.remove('btn-hide');
  //     document.getElementById('btnExitFs')?.classList.add('btn-hide');
  //     document.getElementById('topContainer')?.classList.remove('top-fs');
  //   }
  // }

  onSubmitChat() {

    const history = this.chatHistory().slice();
      history.push({
        id: 'asdf',
        content: this.chatForm.get('content')?.value,
        role: ChatRole.user
      });
  
      this.chatHistory.set(history);

      history.push({
        id: '0',
        content: '',
        role: ChatRole.assistant
      });
  
      this.chatHistory.set(history);
    setTimeout(() => {
      
  history.splice(history.length - 1, 1, {
    id: '53545',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    role: ChatRole.system
  });
  
      this.chatHistory.set(history);
      console.log(this.chatHistory());
    }, 10000);

    
  }
}
