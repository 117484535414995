<div class="wrapper" role="banner">
  <div class="cog--mq">
    <div class="hero-banner-text">
      <h1>We are unable to locate your requested page.</h1>
      <h2>Error 404 - page not found.</h2>
    </div>
    <div class="cgi-box">
      <div class="white-box"></div>
    </div>
  </div>

  <div class="hr-strip"></div>
  <p>The page you requested is no longer available or may have moved.</p>
</div>
