import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../../user-profile/services/profile.service';
import { API_URL } from '../../../constants/api.constant';
import { IMyStuffResponse } from '../../../interfaces/i-api-responses';

@Injectable({
  providedIn: 'root'
})
export class MyStuffService {

  #http = inject(HttpClient);
  #profileService = inject(ProfileService);

  constructor() { }

  getMyStuff() {
    const requestBody = {userId: this.#profileService.userProfile().id };
    const url = `${API_URL}/getBookMarks`;
    return this.#http.post<IMyStuffResponse[]>(url, requestBody);
  }
}
