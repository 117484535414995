import { Component, inject, OnInit } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileComponent } from '../user-profile/profile/profile.component';
import { InterestsComponent } from '../user-profile/interests/interests.component';
import { Router, RouterModule } from '@angular/router';
import { InterestType, RoutePath } from '../../enums/api.enum';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormFieldModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ProfileService } from '../user-profile/services/profile.service';
import { IInterest } from '../../interfaces/i-interest';
import { AlertEnum } from '../../shared/alert/alert.enum';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AlertService } from '../../shared/alert/alert.service';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { MatChipsModule } from '@angular/material/chips';
import { convertToBoolean } from '../../utilities/formating';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    ProfileComponent,
    InterestsComponent,
    RouterModule,
    ReactiveFormsModule,
    LabelModule,
    TextBoxModule,
    DropDownsModule,
    FormFieldModule,
    MatChipsModule
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent implements OnInit {
  exitLink = `/${RoutePath.Feed}`;
  profileForm!: FormGroup;
  interestForm: FormGroup;
  showProfileFormErrorMessage = false;

  userBadge = '';
  showDefaultInterestList = true;

  generalInterestDataSource: IInterest[] = [];
  generalInterestData: IInterest[] = [];
  generalInterestGroupedData: GroupResult[] = [];

  buInterestDataSource: IInterest[] = [];
  buInterestData: IInterest[] = [];
  buInterestGroupedData: GroupResult[] = [];

  govAgenciesInterestDataSource: IInterest[] = [];
  govAgenciesInterestData: IInterest[] = [];
  govAgenciesInterestGroupedData: GroupResult[] = [];

  defaultInterestsDataSource: IInterest[] = [];
  defaultInterestsData: IInterest[] = [];
  profileService = inject(ProfileService);

  #formBuilder = inject(FormBuilder);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #apiService = inject(ApiService);
  #router = inject(Router);

  constructor() {
    this.#getAllInterestsList();

    this.interestForm = this.#formBuilder.group({
      generalInterests: [''],
      buInterests: [''],
      govAgenciesInterests: ['']
    });
  }

  ngOnInit(): void {
    this.userBadge = this.profileService.accountAvatar();

    /*
      Parse out the first and last name given by MS Graph.  Format given [lastName, firstName midInitial (company name)]
    */
    const name = this.profileService.accountProfile()?.displayName;
    const nameArray = name?.split(',');
    const lastName = nameArray![0].trim();
    let fName = nameArray![1].trim().split(' ');
    const firstName = fName[0];

    this.profileForm = this.#formBuilder.group({
      firstName: new FormControl(firstName),
      lastName: new FormControl(lastName),
      email: new FormControl(this.profileService.accountProfile()!.mail),
      businessUnit: new FormControl(null, [Validators.required])
    });
  }

  onSaveProfile() {
    this.#spinnerService.show();
    if (this.profileForm.valid) {
      this.showProfileFormErrorMessage = false;

      // save form
      const requestBody = {
        businessUnitId: this.profileForm.get('businessUnit')?.value.id,
        firstName: this.profileForm.get('firstName')?.value,
        lastName: this.profileForm.get('lastName')?.value,
        email: this.profileForm.get('email')?.value
      };

      this.profileService.registration(requestBody).subscribe({
        next: resp => {
          if (resp.result) {
            this.defaultInterestsData = this.defaultInterestsDataSource.slice()
            this.defaultInterestsData.push(this.profileForm.get('businessUnit')?.value);

            // Update user profile 
            this.profileService.userProfile.set({
              id: resp.id,
              firstName: resp.firstName,
              lastName: resp.lastName,
              email: resp.email,
              businessUnitId: resp.businessUnitId,
              firstTimeUser: convertToBoolean(resp.firstTimeUser!)
            });

            this.#apiService.apiBaseUrl = resp.apiUrl!;
            this.#apiService.apiKey = resp.apiKey!;
          } else {
            this.#alertService.showAlert({
              message: 'An error occurred while saving your profile.  Please try again.',
              type: AlertEnum.error,
            });
          }

          this.#spinnerService.hide();
        },
        error: err => {
          this.#alertService.showAlert({
            message: err.message,
            type: AlertEnum.error,
          });

          this.#spinnerService.hide();
        }
      });
    } else {
      // if (this.profileForm.get('businessUnit')?.invalid && (this.profileForm.get('businessUnit')?.dirty || this.profileForm.get('businessUnit')?.touched)) {
      if (this.profileForm.get('businessUnit')?.invalid) {

        this.showProfileFormErrorMessage = this.profileForm.invalid;
      }
      this.#spinnerService.hide();
      return;
    }
  }

  onSaveInterests() {
    this.#spinnerService.show();
    const userInterests: { userId: number, interestId: number }[] = [];
    const selectedInterests = [
      ...this.interestForm.get('buInterests')?.value,
      ...this.interestForm.get('govAgenciesInterests')?.value,
      ...this.interestForm.get('generalInterests')?.value,
      ...this.defaultInterestsData
    ];

    selectedInterests.forEach((item: IInterest) => {
      const interest = { userId: this.profileService.userProfile().id, interestId: item.id };
      userInterests.push(interest);
    });

    this.profileService.saveInterests(userInterests).subscribe({
      next: resp => {
        if (resp.result) {
          this.#router.navigate([RoutePath.Feed]);
          this.#spinnerService.hide();

          this.#alertService.showAlert({
            message: 'Your profile has been created succussfully.',
            type: AlertEnum.success,
          });
        } else {
          this.#alertService.showAlert({
            message: 'An error occurred while saving your profile.',
            type: AlertEnum.error,
          });

          this.#spinnerService.hide();
        }
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });
  }

  onChangeFilterGeneralInterest(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.generalInterestData = this.generalInterestDataSource.filter(contains(searchTerm));
  }

  onChangeFilterBu(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.buInterestData = this.buInterestDataSource.filter(contains(searchTerm));
  }

  onChangeFilterGovAgencies(searchTerm: string): void {
    const contains =
      (value: string) => (item: IInterest) =>
        item.description!.toLowerCase().includes(value.toLowerCase());

    this.govAgenciesInterestData = this.govAgenciesInterestDataSource.filter(contains(searchTerm));
  }

  onToggleShowDefaultList(): void {
    this.showDefaultInterestList = !this.showDefaultInterestList;
  }

  #getAllInterestsList() {
    this.#spinnerService.show();

    this.profileService.getInterests('').subscribe({
      next: resp => {
        this.profileService.allInterests = resp;
        this.#sortInterests(resp);

        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });
  }

  #sortInterests(interestList: IInterest[]): void {
    this.buInterestDataSource = interestList.filter(item => item.type === InterestType.CGIFBU);
    this.generalInterestDataSource = interestList.filter(item => item.type === InterestType.GEN);
    this.govAgenciesInterestDataSource = interestList.filter(item => item.type === InterestType.GA);
    this.defaultInterestsDataSource = interestList.filter(item => item.type === InterestType.DEFAULT);

    // Sort list in alphabetical order
    this.buInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));
    this.generalInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));
    this.govAgenciesInterestDataSource.sort((a, b) => a.description!.localeCompare(b.description!));
    this.defaultInterestsDataSource.sort((a, b) => a.description!.localeCompare(b.description!));

    this.defaultInterestsData = this.defaultInterestsDataSource.slice();

    this.generalInterestData = this.generalInterestDataSource.slice();
    this.generalInterestGroupedData = groupBy(this.generalInterestData, [{ field: 'category' }]) as GroupResult[];

    this.buInterestData = this.buInterestDataSource.slice();
    this.buInterestGroupedData = groupBy(this.buInterestData, [{ field: 'category' }]) as GroupResult[];

    this.govAgenciesInterestData = this.govAgenciesInterestDataSource.slice();
    this.govAgenciesInterestGroupedData = groupBy(this.govAgenciesInterestData, [{ field: 'category' }]) as GroupResult[];
  }

}
