<div class="header-container">
  <span class="source"><span class="label">Source</span> {{dataCardLabel.Post}}</span>

  <span>
    <span class="label">Last update date</span> 
    @if (item.lastUpdateDate) {
      {{item.lastUpdateDate | date: 'yyyy-MM-dd, h:mm a'}}
    } @else {
      Unavailable
    }
    </span>
</div>


<div class="card-header">
    <div class="title">
        <a [routerLink]="['/', postRoute, post.postId]">{{post.title}}</a>
    </div>

    <div class="sensitivity-label">
        <mat-icon class="material-symbols-outlined" fontIcon="shield" />
        <span><strong>Sensitivity:</strong> {{ sensitivityLabel.label }}</span>
    </div>
</div>

<div class="card-content">
    <div class="desc">{{post.post}}</div>

    @if (attachedFiles.length > 0) {
        <div class="attached-files">
          <div>Attached files:</div>
          <ul class="file-list">
            @for(file of attachedFiles; track file) {
              <li>
                <a [href]="file.path" target="_blank">{{file.name}}</a>
              </li>
            }
          </ul>
        </div>
      }



    <!-- SHOW KEYWORDS -->
    @if (item.keyphrases!.length > 0) {
      <mat-chip-set
        class="chip-list"
        aria-label="Searched items in people, keyphrases and organizations">
        @for (item of item.keyphrases!; track item) {
          <mat-chip>{{ item }}</mat-chip>
        }
      </mat-chip-set>
    }
    <!-- SHOW KEYWORDS -->
</div>