import { Routes } from '@angular/router';
import { UiLayoutComponent } from './ui-layout/ui-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthComponent } from './pages/auth/auth.component';
import { UnifiedSearchComponent } from './pages/unified-search/unified-search.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { RoutePath } from './enums/api.enum';
import { RegistrationComponent } from './pages/registration/registration.component';
import { RegistrationGuard } from './guards/registration.guard';
import { FeedPageComponent } from './pages/feed-page/feed-page.component';
import { MyStuffComponent } from './pages/my-stuff/my-stuff.component';
import { PostListComponent } from './pages/my-posts/post-list/post-list.component';
import { ViewPostComponent } from './pages/my-posts/view-post/view-post.component';
import { AddPostComponent } from './pages/my-posts/add-post/add-post.component';
import { MsalGuard } from "@azure/msal-angular";
import { FailedComponent } from "./pages/failed/failed.component";
import { AiAssistComponent } from './pages/ai-assist/ai-assist.component';
import { BdAssistComponent } from './pages/bd-assist/bd-assist.component';
import { AiConverseComponent } from './pages/ai-converse/ai-converse.component';

export const routes: Routes = [
  // {
  //   path: RoutePath.Root,
  //   redirectTo: `${RoutePath.SignIn}`,
  //   pathMatch: 'full'
  // },
  // {
  //   path: RoutePath.SignIn,
  //   component: AuthComponent,
  // },
  {
    path: RoutePath.Root,
    component: UiLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: RoutePath.Root,
        redirectTo: `${RoutePath.SignIn}`,
        pathMatch: 'full'
      },
      {
        path: RoutePath.SignIn,
        component: AuthComponent,
      },
      {
        path: RoutePath.Registration,
        component: RegistrationComponent,
        canActivate: [MsalGuard],
        // canActivate: [RegistrationGuard]
      },
      {
        path: RoutePath.Feed,
        component: FeedPageComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.Search,
        component: UnifiedSearchComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.MyStuff,
        component: MyStuffComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.MyPosts,
        component: PostListComponent,
        canActivate: [MsalGuard],
      },
      {
        path: `${RoutePath.MyPosts}/:id`,
        component: ViewPostComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.AddPost,
        component: AddPostComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.UserProfile,
        component: UserProfileComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.AIAssist,
        component: AiAssistComponent,
        canActivate: [MsalGuard],
      },
      {
        path:`${RoutePath.AIAssist}/:id`,
        component: AiAssistComponent,
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.BDAssist,
        component: AiConverseComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'login-failed',
        component: FailedComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  },
  // {
  //   path: 'login-failed',
  //   component: FailedComponent
  // },
  // {
  //   path: '**',
  //   component: PageNotFoundComponent
  // }
];
