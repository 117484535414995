import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  isAuthenticated = signal<boolean>(false);
  #baseUrl = '';
  #key = '';

  get apiBaseUrl() {
    return this.#baseUrl;
  }

  set apiBaseUrl(url: string) {
    this.#baseUrl = url;
  }

  get apiKey() {
    return this.#key;
  }

  set apiKey(key: string) {
    this.#key = key;
  }

  
}
