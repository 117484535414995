import { Component, Input, OnInit } from '@angular/core';
import { IResultItem } from '../../../../../../interfaces/i-result-item';
import { ContentComponent } from "../../../content/content.component";
import { CommonModule } from '@angular/common';
import { dataJsonIcon, fileCsvIcon, fileExcelIcon, filePdfIcon, filePptIcon, fileTxtIcon, fileVideoIcon, fileWordIcon, fileZipIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { MatIconModule } from '@angular/material/icon';
import { ISensitivityLabel } from '../../../../../../interfaces/i-sensitivity-label';
import { SENSITIVITY_LABELS } from '../../../../../../constants/sensitivity.constants';
import { DataCardLabel } from '../../../../../../enums/api.enum';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'card-snf',
  standalone: true,
  imports: [
    CommonModule,
    ContentComponent,
    IconsModule,
    MatIconModule,
    MatChipsModule
  ],
  templateUrl: './snf-card.component.html',
  styleUrl: './snf-card.component.scss'
})
export class SnfCardComponent implements OnInit {
  @Input() item!: IResultItem;
  description = '';
  title = '';
  documentExtension = '';
  sensitivityLabel: ISensitivityLabel = { id: 0, label: 'TBD' };
  dataCardLabel = DataCardLabel;

  protected readonly fileWordIcon: SVGIcon = fileWordIcon;
  protected readonly fileCsvIcon: SVGIcon = fileCsvIcon;
  protected readonly filePptIcon: SVGIcon = filePptIcon;
  protected readonly filePdfIcon: SVGIcon = filePdfIcon;
  protected readonly fileTxtIcon: SVGIcon = fileTxtIcon;
  protected readonly fileVideoIcon: SVGIcon = fileVideoIcon;
  protected readonly fileExcelIcon: SVGIcon = fileExcelIcon;
  protected readonly fileZipIcon: SVGIcon = fileZipIcon;
  protected readonly dataJsonIcon: SVGIcon = dataJsonIcon;

  ngOnInit(): void {
    const fileExtRegEx = /(?:\.([^.]+))?$/;
    let desc = this.item.highlights?.unifiedContent[0]!;
    this.description = desc.replaceAll('\\n', ' ');

    // item.metadataStorageName is a file name.
    if (this.item.metadataStorageName) {
      // Get the index of the period before the file extension.
      const fileExtSeparatorIdx = this.item.metadataStorageName.lastIndexOf('.');

      // The metadataStorageName can have multiple periods in the name.  Therefore, we cannot use .split().  We have to extract the name with .substring().
      this.title = this.item.metadataStorageName.substring(0, fileExtSeparatorIdx);

      this.documentExtension = fileExtRegEx.exec(this.item.metadataStorageName)![1];
    }

    if (this.item.sensitivityLabel) {
      this.sensitivityLabel = SENSITIVITY_LABELS.find(label => label.id === +this.item.sensitivityLabel!)!;
    }
  }
}
