@if (open) {
  <kendo-dialog
    id="add-post-dialog"
    title="Make a post"
    [themeColor]="dialogThemeColor"
    [height]="dialogHeight"
    [width]="dialogWidth"
    [minWidth]="dialogMinWidth"
    [maxWidth]="dialogMaxWidth"
    (close)="close()">
    <app-add-post [showInDialog]="true" (closeDialogEvent)="close()"></app-add-post>
  </kendo-dialog>
}
