<div class="result-list-header" [ngClass]="{'result-list-header-sm': isSmallViewPort}">
  <kendo-textbox
    class="search-input"
    [ngClass]="{'search-input-lg': isSmallViewPort}"
    placeholder="Search within results..."
    (valueChange)="onFilter($event)">
    <ng-template kendoTextBoxSuffixTemplate [showSeparator]="false">
      <div class="search-icon-wrapper">
        <mat-icon
          aria-hidden="false"
          aria-label="Search"
          fontIcon="search"/>
      </div>
    </ng-template>
  </kendo-textbox>

  <div class="btns-wrapper">
    <button kendoButton type="button" fillMode="outline" [svgIcon]="fileExcelIcon" (click)="excelexport.save()"
            [ngClass]="{'btn-sm': isSmallViewPort}">
      Export To Excel
    </button>

    @if (isFeedPage) {
      <button class="btn-feeds" [svgIcon]="arrowRotateCwSmallIcon" kendoButton themeColor="primary"
              (click)="refreshFeed()">Refresh feed
      </button>
    }
  </div>
</div>

<kendo-listview
  [kendoListViewBinding]="listData"
  [pageable]="pagerSettings"
  [pageSize]="pageSize"
  containerClass="listview-content">
<!--  (scrollBottom)="loadMore()"-->
  <ng-template
    kendoListViewItemTemplate
    let-dataItem="dataItem"
    let-isLast="isLast">
    <app-result-card [item]="dataItem"></app-result-card>
  </ng-template>
  <ng-template kendoListViewFooterTemplate>
    <div class="footer-note"></div>
  </ng-template>
</kendo-listview>

<kendo-excelexport
  [data]="searchList"
  fileName="cgif_social_search.xlsx"
  #excelexport>
  <kendo-excelexport-column
    field="metadataStorageName"
    title="File Name">
  </kendo-excelexport-column>
  <kendo-excelexport-column
    field="metadataStoragePath"
    title="URL">
  </kendo-excelexport-column>
  <kendo-excelexport-column
    field="dataCard"
    title="Source">
  </kendo-excelexport-column>
  <kendo-excelexport-column
    field="highlights.unifiedContent"
    title="Excerpt">
  </kendo-excelexport-column>
</kendo-excelexport>
