@defer (when businessUnits.length > 0) {
  <div class="profile-wrapper">
    <div class="badge-wrapper">
      <div class="avatar">{{ profileService.accountAvatar() }}</div>
    </div>

    <div class="right-side">
      <div class="account-info">
        <div>
          <h2>Contact Details</h2>
          <ul>
            <li>
              <div class="contact-detail">
                <mat-icon class="material-symbols-outlined icon" aria-label="Name">person</mat-icon>
                <span>{{profileService.accountProfile()?.displayName}}</span>
              </div>
            </li>
            <li>
              <div class="contact-detail">
                <mat-icon class="material-symbols-outlined icon" aria-label="Email">mail</mat-icon>
                <span>{{profileService.accountProfile()?.mail}}</span>
              </div>
            </li>
            @if (profileService.accountProfile()?.jobTitle) {
              <li>
              <div class="contact-detail">
                <mat-icon class="material-symbols-outlined icon" aria-label="Job Title">title</mat-icon>
                <span>{{profileService.accountProfile()?.jobTitle}}</span>
              </div>
            </li>
            }
          </ul>
        </div>
      </div>
            
      <div class="form-wrapper">
        <form [formGroup]="profileForm">
          <div class="field-container">
            <kendo-label text="Select your business unit">
              <kendo-dropdownlist
                formControlName="businessUnit"
                textField="description"
                valueField="id"
                adaptiveMode="auto"
                [data]="businessUnits">
              </kendo-dropdownlist>
            </kendo-label>
          </div>

          <div class="k-form-buttons btn-submit" kendoRippleContainer>
            <button kendoButton themeColor="primary" (click)="onSubmitForm()">
              Save
            </button>
          </div>
        </form>
      </div>      
    </div>

    <!-- <div class="badge-wrapper">
        <div class="badge">{{ profileService.accountAvatar() }}</div>
      </div>
      <div class="form-wrapper">
        <form [formGroup]="profileForm">
          <div class="field-container">
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="firstName"
                text="First Name"></kendo-label>
              <kendo-textbox
                formControlName="firstName"
                #firstName
                showErrorIcon="initial"
                required></kendo-textbox>

              <kendo-formerror>First name is required</kendo-formerror>
            </kendo-formfield>
          </div>

          <div class="field-container">
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="lastName"
                text="Last Name"></kendo-label>
              <kendo-textbox
                formControlName="lastName"
                #lastName
                showErrorIcon="initial"
                required></kendo-textbox>

              <kendo-formerror>Last name is required</kendo-formerror>
            </kendo-formfield>
          </div>

          <div class="field-container">
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="email"
                text="Email"></kendo-label>
              <kendo-textbox
                formControlName="email"
                #email
                showErrorIcon="initial"
                required></kendo-textbox>

              <kendo-formerror>Email is required</kendo-formerror>
            </kendo-formfield>
          </div>

          <div class="field-container">
            <kendo-label text="Select your business unit">
              <kendo-dropdownlist
                formControlName="businessUnit"
                textField="description"
                valueField="id"
                adaptiveMode="auto"
                [data]="businessUnits">
              </kendo-dropdownlist>
            </kendo-label>
          </div>

          <div class="k-form-buttons btn-submit" kendoRippleContainer>
            <button kendoButton themeColor="primary" (click)="onSubmitForm()">
              Save
            </button>
          </div>
        </form>
      </div> -->
  </div>
} @placeholder {
  <div>PLACEHOLDER...</div>
} @loading {
  <div>Loading...</div>
}