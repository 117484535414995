@defer (when apiService.isAuthenticated()) {
  <div class="purple-strip"></div>
  <kendo-appbar position="top" themeColor="inherit">
    @if (!isSmallViewPort) {
      <kendo-appbar-section>
        <button kendoButton fillMode="flat" (click)="drawer!.toggle()" type="button"
          [attr.aria-label]="drawer?.expanded ? 'Minimize menu' : 'Maximize menu'">
          <kendo-svgicon [icon]="menuIcon"></kendo-svgicon>
        </button>
      </kendo-appbar-section>
    }

    <kendo-appbar-section>
      <h1 class="title">
        <img ngSrc="assets/images/CGI_logo_color_rgb.svg" alt="CGI logo of text" height="42" width="69">
        <span class="k-appbar-separator"></span>
        <span class="fuss-title">Social</span>
      </h1>
    </kendo-appbar-section>

    <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      @if (activeUser) {
        <kendo-dropdownbutton [data]="userSettings" (itemClick)="onUserSettingClick($event)" title="User settings"
          buttonClass="user-profile-button">
          {{ profileService.accountAvatar() }}
        </kendo-dropdownbutton>
      }
    </kendo-appbar-section>

    <!-- Small view port menu -->
    @if (isSmallViewPort) {
      <kendo-appbar-section>
        <button kendoButton fillMode="flat" class="overflow-button" #anchor (click)="onToggle()">
          <kendo-svgicon [icon]="menuIcon"></kendo-svgicon>
        </button>

        <!-- Show small viewport menu -->
        @if (showSmallViewportMenu) {
          <kendo-popup [anchor]="anchor.element" [margin]="margin" class="popup-wrapper" [animate]="popupAnimate">
            <div class="popup-content">
              <ul class="popup-menu">
                @for (item of uiLayoutService.navItems(); track item) {
                  <li>
                    <a [routerLink]="item.path" class="sm-viewport-nav-item"
                      [ngClass]="{ 'sm-viewport-nav-item-selected': item.selected }" (click)="onToggleNavigate(item.text)">
                      <!--                    <kendo-svgicon [icon]="item.svgIcon" size="medium"></kendo-svgicon>-->
                      <mat-icon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
                      <span class="nav-item-text">{{ item.text }}</span>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </kendo-popup>
        }
      </kendo-appbar-section>
    }
    <!-- END Small view port menu -->
  </kendo-appbar>

  <div style="margin-top: 5px;"></div>

  @if (!isSmallViewPort) {
    <kendo-drawer-container>
      <kendo-drawer #drawer [items]="uiLayoutService.navItems()" class="left-nav-drawer" [mode]="drawerMode"
        [mini]="drawerMini" (select)="onSelectNavigate($event)" [(expanded)]="expanded">
        <ng-template kendoDrawerItemTemplate let-item>
          <a [routerLink]="item.path" class="nav-item-link" [title]="item.text"
            [ngClass]="{ 'selected-nav-item': item.selected, 'nav-item': !item.selected }">
            <mat-icon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
            @if (expanded) {
              <span class="nav-item-text">{{ item.text }}</span>
            }
          </a>
        </ng-template>
      </kendo-drawer>

      <main>
        <router-outlet></router-outlet>
        <!--      <ai-chat></ai-chat>-->
      </main>
    </kendo-drawer-container>
  }

  @if (isSmallViewPort) {
    <!-- This overlay appears when in a small viewport and the user has clicked the menu button. -->
    @if (showSmallViewportMenu) {
      <div class="popup-overlay" *ngIf="showSmallViewportMenu"></div>
    }

    <main>
      <router-outlet></router-outlet>
    </main>
  }
}
 @placeholder {
  <app-auth></app-auth>
}