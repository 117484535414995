import { inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../constants/api.constant';
import { IAuthResponse } from '../../interfaces/i-api-responses';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated = signal(false);

  #http = inject(HttpClient);

  constructor() {
  }

  signIn(requestBody: { username: string, password: string }) {
    const url = `${API_URL}/login`;
    return this.#http.post<IAuthResponse>(url, requestBody);
  }
}
