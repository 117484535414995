import { Component, effect, inject, OnDestroy } from '@angular/core';
import { SearchResultListComponent } from '../unified-search/search-result-list/search-result-list.component';
import { ShareItemComponent } from '../share-item/share-item.component';
import { SearchService } from '../unified-search/services/search.service';
import { IResultItem } from '../../interfaces/i-result-item';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AlertService } from '../../shared/alert/alert.service';
import { AlertEnum } from '../../shared/alert/alert.enum';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ProfileService } from '../user-profile/services/profile.service';
import { RouterLink } from '@angular/router';
import { NavItemLabels, RoutePath } from '../../enums/api.enum';
import { UiLayoutService } from '../../ui-layout/services/ui-layout.service';
import { ShareItemService } from '../share-item/services/share-item.service';
import { MyPostsService } from '../my-posts/services/my-posts.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { CommonModule } from '@angular/common';
import { AddPostDialogComponent } from '../my-posts/add-post-dialog/add-post-dialog.component';

@Component({
  selector: 'app-feed-page',
  standalone: true,
  imports: [
    CommonModule,
    SearchResultListComponent,
    ShareItemComponent,
    ButtonsModule,
    RouterLink,
    AddPostDialogComponent,
  ],
  templateUrl: './feed-page.component.html',
  styleUrl: './feed-page.component.scss'
})
export class FeedPageComponent implements OnDestroy {
  openShareLinkDialog = false;
  openPostDialog = false;
  searchResults: IResultItem[] = [];
  searchLink = `/${RoutePath.Search}`;
  isSmallViewPort = false;
  noResultsFound = false;

  #searchService = inject(SearchService);
  #profileService = inject(ProfileService);
  #shareItemService = inject(ShareItemService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #uiLayoutService = inject(UiLayoutService);
  #myPostsService = inject(MyPostsService);
  #breakpointService = inject(BreakpointService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();
    this.#getFeed();

    effect(() => {
      this.openShareLinkDialog = this.#shareItemService.openShareLinkDialog();
      this.openPostDialog = this.#myPostsService.openPostDialog();
    });
  }


  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doRefreshFeed(event: any) {
    if (event) {
      this.#getFeed();
    }
  }

  /**
   * Set the search menu item in the left nav as selected.
   */
  setSearchMenuItem() {
    this.#uiLayoutService.updateNavItems(NavItemLabels.Search);
  }

  #getFeed() {
    this.#spinnerService.show();
    this.noResultsFound = false;

    const searchSubscription = this.#searchService.fetchFeedResults({userId: this.#profileService.userProfile().id}).subscribe({
      next: resp => {
        this.searchResults = this.#searchService.manageSearchResults(resp);
        this.noResultsFound = (this.searchResults.length === 0);
        this.#spinnerService.hide();
      },
      error: (err) => {
        this.#alertService.showAlert({
          message: 'An error occurred getting your feed list.',
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });

    this.#subscriptions.push(searchSubscription);
  }
}
