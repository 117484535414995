<div class="header-container">
    <span class="source"><span class="label">Source</span> {{dataCardLabel.Govwin}}</span>
    <span>
        <span class="label">Last Update</span> 
        @if (data.updateDate) {
            {{data.updateDate| date: 'yyyy-MM-dd, h:mm a'}}
        } @else {
            Unavailable
        }
        </span>
</div>


<div class="title-wrapper">
    <span class="title">
        <a [href]="data.links?.webHref?.href" target="_blank">{{data.govEntity?.title}}</a>
        <mat-icon class="material-symbols-outlined icon-extnl-link" fontIcon="ungroup" />
    </span>
</div>

<div class="desc-container" [ngClass]="{'read-more': showMoreDesc}">
    <app-content [content]="data.description" />
</div>

<div class="read" [ngClass]="{'btn-read-more': showMoreDesc}">
    <button type="button" mat-button (click)="onReadMore()">
        @if(!showMoreDesc) {
        <ng-container> 
            <!-- <mat-icon class="material-symbols-outlined" fontIcon="keyboard_arrow_down" /> -->
            Read more
        </ng-container>
        } @else {
        <ng-container>
            <!-- <mat-icon class="material-symbols-outlined" fontIcon="keyboard_arrow_up" /> -->
            Read less
        </ng-container>
        }
    </button>
</div>

<mat-list role="list">
    <mat-list-item role="listitem" class="list-item"><span class="label">Opportunity
            ID</span>{{data.iqOppId}}</mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Status</span> {{data.status}}</mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Solicitation number</span>
        {{data.solicitationNumber}}</mat-list-item>

    <mat-list-item role="listitem" class="list-item"><span class="label">Competition type</span>
        @if (data.competitionTypes) {
        {{data.competitionTypes[0].title}}
        }
    </mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Type of award</span>
        {{data.typeOfAward}}</mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Primary requirement</span>
        {{data.primaryRequirement}}</mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Duration</span>
        {{data.duration}}</mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Contract type</span>
        @if (data.contractTypes) {
        {{data.contractTypes[0].title}}
        }
    </mat-list-item>
    <mat-list-item role="listitem" class="list-item"><span class="label">Number of expected awards</span>
        {{data.status}}</mat-list-item>
</mat-list>

<!-- SHOW KEYWORDS -->
@if (keyphrases!.length > 0) {
<div>
    <mat-chip-set class="chip-list" aria-label="Searched items in people, keyphrases and organizations">
        @for (item of keyphrases!; track item) {
        <mat-chip>{{ item }}</mat-chip>
        }
    </mat-chip-set>
</div>
}
<!-- SHOW KEYWORDS -->