<h1>Complete your Profile</h1>
<mat-horizontal-stepper #stepper linear>
  <mat-step [stepControl]="profileForm" [editable]="false">
    <form [formGroup]="profileForm">
      <ng-template matStepLabel>Profile</ng-template>

      <ng-template matStepContent>
        @defer (when buInterestDataSource.length > 0) {
        <div class="profile-wrapper">
          <div class="badge-wrapper">
            <div class="avatar">{{ profileService.accountAvatar() }}</div>
          </div>

          <div class="right-side">
            <div class="account-info">
              <div>
                <h2>Select your business unit before proceeding to the next step.</h2>
              </div>
              <div class="form-wrapper">
                <ul>
                  <li>
                    <div class="contact-detail">
                      <mat-icon class="material-symbols-outlined icon" aria-label="Name">person</mat-icon>
                      <span>{{profileService.accountProfile()?.displayName}}</span>
                    </div>
                  </li>
                  <li>
                    <div class="contact-detail">
                      <mat-icon class="material-symbols-outlined icon" aria-label="Email">mail</mat-icon>
                      <span>{{profileService.accountProfile()?.mail}}</span>
                    </div>
                  </li>
                  @if (profileService.accountProfile()?.jobTitle) {
                  <li>
                    <div class="contact-detail">
                      <mat-icon class="material-symbols-outlined icon" aria-label="Job Title">title</mat-icon>
                      <span>{{profileService.accountProfile()?.jobTitle}}</span>
                    </div>
                  </li>
                  }
                </ul>
              </div>
            </div>

            <div class="form-wrapper">
              <div class="field-container">
                <kendo-label text="Select your business unit">
                  <kendo-dropdownlist formControlName="businessUnit" textField="description" valueField="id"
                    adaptiveMode="auto" required [data]="buInterestDataSource">
                  </kendo-dropdownlist>
                </kendo-label>
                @if (showProfileFormErrorMessage) {
                <kendo-formerror>Business unit is required</kendo-formerror>
                }
              </div>

              <div class="btn-profile">
                <button mat-flat-button color="accent" matStepperNext (click)="onSaveProfile()">Next</button>
              </div>
            </div>
          </div>
        </div>
        } @placeholder {
        <div>PLACEHOLDER...</div>
        } @loading {
        <div>Loading...</div>
        }
      </ng-template>
    </form>
  </mat-step>

  <mat-step [stepControl]="interestForm" [editable]="false">
    <form [formGroup]="interestForm">
      <ng-template matStepLabel>Interests</ng-template>
      <ng-template matStepContent>
        <div class="interest-wrapper">
          <h2>What are you interested in?</h2>
          <h3>Select topics you are interested in to help personalize your search experience.</h3>

          <div class="interest-container">
            <kendo-formfield showHints="always">
              <kendo-label text="Other CGI Federal Business Units" class="label-txt">
                <kendo-multiselect formControlName="buInterests" [data]="buInterestData" [filterable]="true"
                  textField="description" valueField="id" (filterChange)="onChangeFilterBu($event)"></kendo-multiselect>
              </kendo-label>
              <kendo-formhint>Select your interests the list.</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="interest-container">
            <kendo-formfield showHints="always">
              <kendo-label text="Government Agencies" class="label-txt">
                <kendo-multiselect formControlName="govAgenciesInterests" [data]="govAgenciesInterestData"
                  [filterable]="true" textField="description" valueField="id"
                  (filterChange)="onChangeFilterGovAgencies($event)"></kendo-multiselect>
              </kendo-label>
              <kendo-formhint>Select your interests the list.</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="interest-container">
            <kendo-formfield showHints="always">
              <kendo-label text="General / Technology" class="label-txt">
                <kendo-multiselect formControlName="generalInterests" [data]="generalInterestData" [filterable]="true"
                  textField="description" valueField="id"
                  (filterChange)="onChangeFilterGeneralInterest($event)"></kendo-multiselect>
              </kendo-label>
              <kendo-formhint>Select your interests the list.</kendo-formhint>
            </kendo-formfield>
          </div>

          <div class="default-interests">
            <h3>Default interests applied to all members.
              <button mat-button type="button" (click)="onToggleShowDefaultList()">
                @if (showDefaultInterestList) {
                Hide
                } @else {
                Show
                }
              </button>
            </h3>

            @if (showDefaultInterestList) {
            <mat-chip-set aria-label="Fish selection">
              @for (item of defaultInterestsData; track item.id) {
              <mat-chip>{{item.description}}</mat-chip>
              }
            </mat-chip-set>
            }
          </div>

          <div class="btns-next-prev">
            <button mat-flat-button color="accent" type="button" matStepperPrevious>Back</button>
            <button mat-flat-button color="accent" matStepperNext type="button"
              (click)="onSaveInterests()">Next</button>
          </div>
        </div>
      </ng-template>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <ng-template matStepContent>
      <p>You are now done! </p>
      <div class="link">
        <a [routerLink]="exitLink">Exit to search</a>
      </div>
      <div class="btns-next-prev">
        <button mat-flat-button color="accent" matStepperPrevious>Back</button>
        <button mat-flat-button color="accent">Complete</button>
      </div>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>