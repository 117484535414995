<div class="add-post-container" [ngClass]="{'no-dialog': !showInDialog, 'sm-vp': isSmallViewport}">
  <div class="card-wrapper" [ngClass]="{'card-wrapper-sm': isSmallViewport}">
    <div class="card-header">
      <h1 [ngClass]="{'h1-sm': isSmallViewport}">Post a comment</h1>
      @if (showInDialog && link) {
        <h2 class="share-file">{{ link.metadataStorageName }}</h2>
        <!--    <p class="share-link">{{ link.metadataStoragePath }}</p>-->
      }
    </div>

    <form [formGroup]="postForm" (ngSubmit)="onSubmitPost()">
      <div class="input-spacer">
        <!-- Sensitivity labels -->
        <div class="input-wrapper sensitivity">
          <kendo-label labelCssClass="required" text="Sensitivity label" />
          @if (sensitivityLabel.id !== 0) {
          <button mat-raised-button type="button" #tooltip="matTooltip" matTooltipPosition="right"
            matTooltip="Apply a sensitivity label to your content to keep it compliant with your organization's information protection policies."
            aria-label="Apply a sensitivity label to your content to keep it compliant with your organization's information protection policies."
            [matMenuTriggerFor]="categories">
            <mat-icon
              [ngClass]="{'red-shield': postForm.get('sensitivity')?.value > 11, 'material-symbols-outlined': postForm.get('sensitivity')?.value < 12}">
              shield
            </mat-icon>
            {{ sensitivityLabel.label }}
            <mat-icon iconPositionEnd>expand_more</mat-icon>
          </button>
          }
          @if (sensitivityLabel.id === 0) {
          <button mat-raised-button type="button" #tooltip="matTooltip" matTooltipPosition="right"
            matTooltip="Apply a sensitivity label to your content to keep it compliant with your organization's information protection policies."
            aria-label="Apply a sensitivity label to your content to keep it compliant with your organization's information protection policies."
            [matMenuTriggerFor]="categories">
            <mat-icon class="material-symbols-outlined">gpp_maybe</mat-icon>
            Sensitivity label
            <mat-icon iconPositionEnd>expand_more</mat-icon>
          </button>
          }

          @if (!this.isSensitivityValid()) {
          <kendo-formerror>You must select a sensitivity label</kendo-formerror>
          }

          <mat-menu #categories="matMenu">
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Non-work-related information owned by members should be labeled as Personal."
              aria-label="Non-work-related information owned by members should be labeled as Personal."
              (click)="onSelectSensitivity(1)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              Personal
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="This information is not sensitive and requires no protection. There are no adverse effects to CGIF if public information is disclosed."
              aria-label="This information is not sensitive and requires no protection. There are no adverse effects to CGIF if public information is disclosed."
              (click)="onSelectSensitivity(2)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              Public
            </button>
            <button mat-menu-item type="button" [matMenuTriggerFor]="internal">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Internal
            </button>
            <button mat-menu-item type="button" [matMenuTriggerFor]="conf">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Confidential
            </button>
            <button mat-menu-item type="button" [matMenuTriggerFor]="highlyConf">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Highly Confidential
            </button>
            <button mat-menu-item type="button" [matMenuTriggerFor]="cui">
              <mat-icon class="material-symbols-outlined red-shield">safety_check_off</mat-icon>
              CUI
            </button>
          </mat-menu>

          <mat-menu #internal="matMenu">
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals."
              aria-label="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals."
              (click)="onSelectSensitivity(3)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Internal
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals, and will be shared with a Third Party."
              aria-label="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals, and will be shared with a Third Party."
              (click)="onSelectSensitivity(4)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              Third Party
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              aria-label="Information for which an unauthorized disclosure could have a limited adverse effect on CGIF operations, organizational assets, or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              (click)="onSelectSensitivity(5)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI Parent & Affiliate
            </button>
          </mat-menu>
          <mat-menu #conf="matMenu">
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals."
              aria-label="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals."
              (click)="onSelectSensitivity(6)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Confidential
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals, and will be shared with a Third Party."
              aria-label="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals, and will be shared with a Third Party."
              (click)="onSelectSensitivity(7)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              Third Party
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              aria-label="Information for which an unauthorized disclosure could cause a serious adverse effect on organizational operations, organizational assets or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              (click)="onSelectSensitivity(8)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI Parent & Affiliate
            </button>
          </mat-menu>
          <mat-menu #highlyConf="matMenu">
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals."
              aria-label="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals."
              (click)="onSelectSensitivity(9)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI - Highly Confidential
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals, and will be shared with a Third Party."
              aria-label="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals, and will be shared with a Third Party."
              (click)="onSelectSensitivity(10)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              Third Party
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              aria-label="Information for which an unauthorized disclosure can have a severe or catastrophic adverse effect on organizational operations, organizational assets or individuals, and will be shared with a CGI Parent and Affiliate.  For questions about information that requires a FOCI Release Determination, please contact Industrial Security (cgifederalsecurity@cgifederal.com)."
              (click)="onSelectSensitivity(11)">
              <mat-icon class="material-symbols-outlined">shield</mat-icon>
              CGI Parent & Affiliate
            </button>
          </mat-menu>
          <mat-menu #cui="matMenu">
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="CUI labels should only be used when the data has been identified as such by the client (includes FOUO, SBU, etc.)"
              aria-label="CUI labels should only be used when the data has been identified as such by the client (includes FOUO, SBU, etc.)"
              (click)="onSelectSensitivity(12)" disabled>
              <mat-icon class="material-symbols-outlined red-shield">safety_check_off</mat-icon>
              CUI
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Information may not be disseminated in any form to foreign governments, foreign nationals, foreign or international organizations, or non-U.S. citizens."
              aria-label="Information may not be disseminated in any form to foreign governments, foreign nationals, foreign or international organizations, or non-U.S. citizens."
              (click)="onSelectSensitivity(13)" disabled>
              <mat-icon class="material-symbols-outlined red-shield">safety_check_off</mat-icon>
              NOFORN
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Includes individuals or employees who enter into a contract with the U.S. to perform a specific job, supply labor and materials, or for the sale of products and services, so long as dissemination is in furtherance of the contractual purpose."
              aria-label="Includes individuals or employees who enter into a contract with the U.S. to perform a specific job, supply labor and materials, or for the sale of products and services, so long as dissemination is in furtherance of the contractual purpose."
              (click)="onSelectSensitivity(14)" disabled>
              <mat-icon class="material-symbols-outlined red-shield">safety_check_off</mat-icon>
              FEDCON
            </button>
            <button mat-menu-item type="button" #tooltip="matTooltip" matTooltipPosition="right"
              matTooltip="Dissemniation authorized only to those individuals, organizations, or entities included on an accompanying dissemination list."
              aria-label="Dissemniation authorized only to those individuals, organizations, or entities included on an accompanying dissemination list."
              (click)="onSelectSensitivity(15)" disabled>
              <mat-icon class="material-symbols-outlined red-shield">safety_check_off</mat-icon>
              DL ONLY
            </button>
          </mat-menu>
        </div>
        <!-- END: Sensitivity labels -->

        <div class="input-wrapper">
          <kendo-formfield class="form-input">
            <kendo-label labelCssClass="k-form-label required" [for]="title" text="Title"></kendo-label>
            <kendo-textbox formControlName="title" #title showErrorIcon="initial" required></kendo-textbox>
            <kendo-formerror>Title is required</kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="input-wrapper">
          <kendo-formfield class="form-input">
            <kendo-label labelCssClass="k-form-label required" [for]="post" text="Post"></kendo-label>
            <kendo-textarea formControlName="post" #post required [rows]="4"></kendo-textarea>
            <kendo-formerror>Post is required</kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="input-wrapper">
          <kendo-formfield showHints="always">
            <kendo-label text="Keywords">
              <kendo-multiselect formControlName="keywords" [data]="interestData" [filterable]="true"
                textField="description" valueField="id" (filterChange)="onChangeFilter($event)">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <span class="template">{{ dataItem.description }}</span>
                </ng-template>
              </kendo-multiselect>
            </kendo-label>
            <kendo-formhint>Select your keywords from the list.</kendo-formhint>
          </kendo-formfield>
        </div>

        <div class="input-wrapper-files">
          <kendo-label style="display: block; margin: .8em 0 .2em" text="Attach file:">
            <kendo-upload [withCredentials]="true" formControlName="attachedFiles" [responseType]="'json'"
              [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" [restrictions]="fileTypeRestrictions"
              [multiple]="false" (blur)="blur()" (focus)="focus()" (error)="error($event)" (select)="select($event)"
              (success)="success($event)" (upload)="upload($event)" (valueChange)="valueChange($event)"
              (complete)="complete()">
            </kendo-upload>
          </kendo-label>
        </div>

        <div class="extras-wrapper">
          <kendo-label text="Enable: &nbsp;" class="enable-post">
            <kendo-switch formControlName="enabled" size="large" trackRounded="full" thumbRounded="full"></kendo-switch>
            <kendo-formhint>Enable others to view your post.</kendo-formhint>
          </kendo-label>

          @if(showAddScreenShareButton) {
          <button mat-raised-button type="button" (click)="showMediaShare=!showMediaShare">
            <mat-icon>add_to_queue</mat-icon>
            Add screen share video
          </button>
          }
        </div>

        <!-- Show medial share -->
        @if (showMediaShare) {
        <div class="media-share-wrapper">
          <app-media-share (isRecordingEvent)="isRecordingEventEmitted($event)"
            (showMediaShareEvent)="showMedaShareEvent($event)"></app-media-share>
        </div>
        }

        <div class="k-actions k-actions-end btn-actions" kendoRippleContainer>
          @if (showInDialog) {
          <button kendoButton type="button" (click)="closeDialog()">Cancel</button>
          }
          <button kendoButton themeColor="primary" type="submit" [disabled]="isRecordingScreenCapture">
            Post
          </button>
        </div>
      </div>
    </form>
  </div>
</div>