import { Component, Input, OnInit } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IResultItem } from '../../../../interfaces/i-result-item';
import { ICommentCard } from '../../../../interfaces/i-comment-card';
import { CommentActionsComponent } from './comment-actions/comment-actions.component';
import { CommonModule } from '@angular/common';
import {
  SVGIcon,
  filePdfIcon,
  filePptIcon,
  fileWordIcon,
  fileExcelIcon,
  fileCsvIcon,
  fileTxtIcon,
  fileVideoIcon,
  fileZipIcon,
  dataJsonIcon
} from '@progress/kendo-svg-icons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ContentComponent } from '../content/content.component';
import { MatChipsModule } from '@angular/material/chips';
import { ICommentActions } from '../../../../interfaces/i-comment-actions';
import { MatIconModule } from '@angular/material/icon';
import { DataCardType } from '../../../../enums/api.enum';
import { GovwinCardComponent } from "./card-types/govwin-card/govwin-card.component";
import { PostCardComponent } from "./card-types/post-card/post-card.component";
import { SnfCardComponent } from "./card-types/snf-card/snf-card.component";

@Component({
  selector: 'app-result-card',
  standalone: true,
  imports: [
    LayoutModule,
    CommentActionsComponent,
    CommonModule,
    IconsModule,
    ContentComponent,
    MatChipsModule,
    MatIconModule,
    GovwinCardComponent,
    PostCardComponent,
    SnfCardComponent
],
  templateUrl: './result-card.component.html',
  styleUrl: './result-card.component.scss'
})
export class ResultCardComponent implements OnInit {
  @Input() item!: IResultItem;
  @Input() card!: ICommentCard;

  // documentExtension = '';
  // chipsList: string[] = [];
  // content: string[] = [];
  actionsItem!: ICommentActions;
  dataCardType = DataCardType;

  // protected readonly fileWordIcon: SVGIcon = fileWordIcon;
  // protected readonly fileCsvIcon: SVGIcon = fileCsvIcon;
  // protected readonly filePptIcon: SVGIcon = filePptIcon;
  // protected readonly filePdfIcon: SVGIcon = filePdfIcon;
  // protected readonly fileTxtIcon: SVGIcon = fileTxtIcon;
  // protected readonly fileVideoIcon: SVGIcon = fileVideoIcon;
  // protected readonly fileExcelIcon: SVGIcon = fileExcelIcon;
  // protected readonly fileZipIcon: SVGIcon = fileZipIcon;
  // protected readonly dataJsonIcon: SVGIcon = dataJsonIcon;


  constructor() {
  }

  ngOnInit(): void {
    // Regex for HTML tags
    // const regex = /(<([^>]+)>)/ig;
    // let allList: string[] = [];

    this.actionsItem = {
      metadataStoragePath: this.item.metadataStoragePath,
      metadataStorageName: this.item.metadataStorageName,
      score: this.item.score,
      dataCard: this.item.dataCard
    };

    // this.documentExtension = this.item.metadataStorageFileExtension;

    // if (this.item.highlights.hasOwnProperty('content') && this.item.highlights.content) {
      // this.content = this.item.highlights.content;
    // } else {
      // this.content = [this.item.content];
    // }

    /*
    Get all possible lists from highlights excluding content and remove any HTML; then combine them into one list.
    Then add them to a list for the chips.
     */
    // if (this.item.highlights.people) {
    //   const list = this.item.highlights.people.map(person => {
    //     return person.replace(regex, '');
    //   });
    //   allList = [...list];
    // }

    // if (this.item.highlights.keyPhrases) {
    //   const list = this.item.highlights.keyPhrases.map(person => {
    //     return person.replace(regex, '');
    //   });
    //   allList = [...list];
    // }

    // if (this.item.highlights.organizations) {
    //   const list = this.item.highlights.organizations.map(person => {
    //     return person.replace(regex, '');
    //   });
    //   allList = [...list];
    // }

    // this.chipsList = [...new Set(allList)];
  }
}
