import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { FormFieldModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { IUserProfile } from '../../../interfaces/i-user-profile';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { ProfileService } from '../services/profile.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AlertService } from '../../../shared/alert/alert.service';
import { AlertEnum } from '../../../shared/alert/alert.enum';
import { InterestType } from '../../../enums/api.enum';
import { IInterest } from '../../../interfaces/i-interest';
import { Subscription } from 'rxjs';
import { ProfileType } from '../../../types/api';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    ButtonModule,
    FilterMenuModule,
    FormFieldModule,
    LabelModule,
    ReactiveFormsModule,
    RippleModule,
    TextBoxModule,
    DropDownsModule,
    MatIconModule
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnDestroy, OnInit {
  profileForm!: FormGroup;
  userProfile!: IUserProfile;
  accountProfile!: ProfileType | null;
  businessUnits: IInterest[] = [];
  selectedBusinessUnit: Partial<IInterest> | undefined;
  profileService = inject(ProfileService);

  // MSAL
  profile!: ProfileType;
  // END MASL

  #formBuilder = inject(FormBuilder);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    // this.userProfile = this.profileService.userProfile();
    // this.accountProfile = this.profileService.accountProfile();


    // this.selectedBusinessUnit = this.profileService.allInterests.find(item => item.id === this.profileService.userProfile().businessUnitId);
    // this.#getBusinessUnitList();

    // this.profileForm = this.#formBuilder.group({
    //   businessUnit: new FormControl(this.selectedBusinessUnit, [Validators.required])
    // });

    // this.profileForm = this.#formBuilder.group({
    //   firstName: new FormControl(this.profileService.userProfile().firstName, [Validators.required]),
    //   lastName: new FormControl(this.profileService.userProfile().lastName, [Validators.required]),
    //   email: new FormControl(this.profileService.userProfile().email, [Validators.required, Validators.email]),
    //   language: new FormControl(this.profileService.userProfile().language),
    //   businessUnit: new FormControl(this.selectedBusinessUnit, [Validators.required])
    // });

  }

  ngOnInit(): void {
    this.userProfile = this.profileService.userProfile();
    this.accountProfile = this.profileService.accountProfile();

    if (this.profileService.userProfile().id > 0) {

    
    this.selectedBusinessUnit = this.profileService.allInterests.find(item => item.id === this.profileService.userProfile().businessUnitId);
    this.#getBusinessUnitList();

    this.profileForm = this.#formBuilder.group({
      businessUnit: new FormControl(this.selectedBusinessUnit, [Validators.required])
    });
    }
}

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSubmitForm() {
    this.#spinnerService.show();

    if (this.profileForm.valid) {
      const requestBody = {
        userId: this.profileService.userProfile()!.id!,
        businessUnitId: this.profileForm.get('businessUnit')?.value.id,
        firstName: this.profileService.userProfile().firstName!,
        lastName: this.profileService.userProfile().lastName!,
        email: this.profileService.userProfile().email!
      };

      const subscription = this.profileService.saveProfile(requestBody).subscribe({
        next: resp => {
          this.#alertService.showAlert({
            message: 'Profile saved.',
            type: AlertEnum.success,
          });
          this.#spinnerService.hide();
        },
        error: err => {
          this.#alertService.showAlert({
            message: err.message,
            type: AlertEnum.error,
          });

          this.#spinnerService.hide();
        }
      });

      this.#subscriptions.push(subscription);
    } else {
      this.profileForm.markAllAsTouched();
      this.#spinnerService.hide();
      return;
    }
  }

  #getBusinessUnitList() {
    this.#spinnerService.show();

    const subscription = this.profileService.getInterests(InterestType.CGIFBU).subscribe({
      next: resp => {
        this.businessUnits = resp;
        this.businessUnits.sort((a, b) => a.description!.localeCompare(b.description!));
        if (this.profileService.userProfile().businessUnitId) {
          this.selectedBusinessUnit = this.businessUnits.find(item => item.id === this.profileService.userProfile().businessUnitId);
        }

        this.profileForm.get('businessUnit')?.setValue(this.selectedBusinessUnit);
        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });

    this.#subscriptions.push(subscription);
  }
}
