<div class="btn-group">
  <div>
    <button mat-raised-button class="btn-record" type="button" [ngClass]="{'is-recording': showIsRecording}"
      (click)="startRecording()">
      <mat-icon>album</mat-icon>
      @if (showIsRecording) {
      Recording 
      @if (!breakpointService.isSmallViewport()) {
        in session
      }
      ...
      } @else {
        Start
        @if (!breakpointService.isSmallViewport()) {
          screen capture
        }
      }
    </button>

    @if (showIsRecording) {
      <button mat-raised-button class="btn-record" type="button" (click)="stopRecording()">
        <mat-icon>stop_circle</mat-icon>
        Stop
        @if (!breakpointService.isSmallViewport()) {
          screen capture
        }
      </button>
    } 
  </div>

  <button mat-raised-button type="button" (click)="cancelScreenCapture()" [disabled]="showIsRecording">
    <mat-icon class="material-symbols-outlined">cancel</mat-icon>
    Cancel
  </button>
</div>

<video class="recorded-video" #recordedVideo controls></video>

@if (showIsRecording) {
<div class="recording">

  <p>Recording is in session...</p>
  <mat-spinner strokeWidth="5" [diameter]="20" color="accent"></mat-spinner>
</div>
}
<div class="media-viewer-container" #mediaViewerContainer>
  <video class="media-viewer" #mediaViewer controls></video>

  <div class="btn-group">
    <button mat-raised-button type="button" class="btn-record" aria-label="Download screen capture" (click)="downloadScreenCapture()">
      <mat-icon>download</mat-icon>
      Download 
    </button>

    <button mat-raised-button type="button" aria-label="Clear screen capture" (click)="clearScreenCapture()">
      <mat-icon class="material-symbols-outlined">clear</mat-icon>
      Clear
    </button>
  </div>
</div>