import { Component, effect, inject } from '@angular/core';
import { AddPostComponent } from '../add-post/add-post.component';
import { DialogModule, DialogThemeColor } from '@progress/kendo-angular-dialog';
import { MyPostsService } from '../services/my-posts.service';
import { BreakpointService } from '../../../services/breakpoint.service';
import { ShareItemService } from '../../share-item/services/share-item.service';

@Component({
  selector: 'add-post-dialog',
  standalone: true,
    imports: [
        AddPostComponent,
        DialogModule
    ],
  templateUrl: './add-post-dialog.component.html',
  styleUrl: './add-post-dialog.component.scss'
})
export class AddPostDialogComponent {
  open = false;
  dialogHeight = '80%';
  dialogWidth = '50%';
  dialogMaxWidth = '80%';
  dialogMinWidth = '30%';
  dialogThemeColor: DialogThemeColor = 'primary';


  #myPostsService = inject(MyPostsService);
  #breakpointService = inject(BreakpointService);
  #shareItemService = inject(ShareItemService);

  constructor() {
    if (this.#breakpointService.isSmallViewport()) {
      this.dialogHeight = '100%';
      this.dialogWidth = '100%';
      this.dialogMaxWidth = 'unset';
    }

    effect(() => {
      this.open = this.#myPostsService.openPostDialog();
    });
  }

  close(): void {
    this.open = false;
    this.#myPostsService.openPostDialog.set(false);
    this.#shareItemService.sharedLink.set({metadataStorageName: '', metadataStoragePath: ''})
  }
}
