import { Component, computed, effect, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { MyPostsService } from '../services/my-posts.service';
import { ProfileService } from '../../user-profile/services/profile.service';
import { AlertEnum } from '../../../shared/alert/alert.enum';
import { IPost } from '../../../interfaces/i-post';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { PagerSettings } from '@progress/kendo-angular-grid/pager/pager-settings';
import { PostCardComponent } from '../post-card/post-card.component';
import { MatIcon } from '@angular/material/icon';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { Event, RouterLink } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { MatButtonModule } from '@angular/material/button';
import { RoutePath } from '../../../enums/api.enum';
import { BreakpointService } from "../../../services/breakpoint.service";

@Component({
  selector: 'app-post-list',
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule,
    PostCardComponent,
    MatIcon,
    TextBoxModule,
    MatButtonModule,
    RouterLink
  ],
  templateUrl: './post-list.component.html',
  styleUrl: './post-list.component.scss'
})
export class PostListComponent implements OnDestroy, OnInit {
  isSmallViewPort = false;
  listData: IPost[] = [];
  pagerSettings: PagerSettings = {
    previousNext: false,
    pageSizes: false,
    buttonCount: 9,
  };
  pageSize = 10;
  addPostRoute = `/${RoutePath.AddPost}`;
  fetching = signal<boolean>(true);

  #listDataSource: IPost[] = [];
  #breakpointService = inject(BreakpointService);
  #myPostsService = inject(MyPostsService);
  #profileService = inject(ProfileService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();
  }
  
  ngOnInit(): void {
    this.#spinnerService.show();
    setTimeout(() => {
      this.getPosts();
    }, 500);
  }
  
  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  
  getPosts() {
    // this.#spinnerService.show();

    const subscription = this.#myPostsService.getPosts({ userId: this.#profileService.userProfile().id }).subscribe({
      next: (resp: any) => {
        if (Array.isArray(resp)) {
          // TODO: Temp code until we get a standard format of an API response
          const keyExists = resp.filter(obj => Object.hasOwn(obj, 'result')).length > 0;

          // The response is [{"result": false}]
          if (keyExists && !resp[0]['result']) {
            console.log('No posts available');
            // END Temp code
          } else {
            const list = resp.sort((a, b) => {
              const dt1 = new Date(a.dateUpdated!);
              const dt2 = new Date(b.dateUpdated!);
              return +dt2 - +dt1;
            });

            this.listData = list.map(item => {
              let keyPhrases = [];
              if (item.keywords) {
                if (Array.isArray(item.keywords)) {
                  keyPhrases = item.keywords;
                } else {
                  keyPhrases = JSON.parse(item.keywords);
                }
              }
              
              return {
                ...item,
                keywords: keyPhrases,
                metadataStoragePath: item.metadata_storage_path,
                comments: item.comments?.sort((a: any, b: any) => {
                  const dt1 = new Date(a.dateCreated!);
                  const dt2 = new Date(b.dateCreated!);
                  return +dt2 - +dt1;
                })
              };
            });

            this.#listDataSource = this.listData.slice();

          }
        } else {
          this.#alertService.showAlert({
            message: 'Error occurred while getting your posts.',
            type: AlertEnum.error,
          });
        }
        
        this.fetching.set(false);
        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
        this.fetching.set(false);
      }
    });

    this.#subscriptions.push(subscription);
  }

  onFilterSearch(value: Event): void {
    if (value.toString() !== '') {
      this.listData = process(this.listData, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'post',
              operator: 'contains',
              value: value,
            },
            {
              field: 'title',
              operator: 'contains',
              value: value,
            },
            {
              field: 'firstName',
              operator: 'contains',
              value: value,
            },
            {
              field: 'lastName',
              operator: 'contains',
              value: value,
            }
          ],
        },
      }).data;
    } else {
      this.listData = this.#listDataSource.slice();
    }

    // this.dataBinding!.skip = 0;
  }

}
