import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [
    SafeHtmlPipe
  ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ContentComponent implements OnInit{
  @Input() content!: any;

  ngOnInit(): void {
  }
}
