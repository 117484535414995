import { Component, Input, OnInit } from '@angular/core';
import { IResultItem } from '../../../../../../interfaces/i-result-item';
import { ContentComponent } from "../../../content/content.component";
import { CommonModule, DatePipe } from '@angular/common';
import { IGovWin } from '../../../../../../interfaces/i-gov-win';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { DataCardLabel } from '../../../../../../enums/api.enum';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'card-govwin',
  standalone: true,
  imports: [
    CommonModule,
    ContentComponent,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule
  ],
  host: {
    '[style.--mdc-list-list-item-one-line-container-height]': '30'
  },
  templateUrl: './govwin-card.component.html',
  styleUrl: './govwin-card.component.scss'
})
export class GovwinCardComponent implements OnInit {
  @Input() item!: IResultItem;
  data!: Partial<IGovWin>;
  showMoreDesc = false;
  dataCardLabel = DataCardLabel;
  keyphrases: string[] = [];

  ngOnInit(): void {
    const dataContent = JSON.parse(this.item.unifiedContent);

    this.data = JSON.parse(dataContent['merged_content']);
    console.log('GovwinCardComponent.data', this.data);

    if (this.data.smartTagObject && this.data.smartTagObject.length > 0) {
      this.data.smartTagObject.forEach(item => {
        this.keyphrases.push(item.name);
      });
    }
  }

  onReadMore() {
    this.showMoreDesc = !this.showMoreDesc;
  }
}
