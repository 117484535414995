import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiService } from '../services/api.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const requestUrl = req.url;
  const apiService = inject(ApiService);
  let baseUrl: string[];
  let endPoint = '';

  if (requestUrl.indexOf('?') > -1) {
    baseUrl = requestUrl.split('?');
    baseUrl = requestUrl.split('/')
  } else {
    baseUrl = requestUrl.split('/');
  }
  endPoint = baseUrl[baseUrl[0].length - 1];

  if (endPoint !== 'login') {
    // Clone the request because it's immutable
    

    if (endPoint === 'fileUpload') {
      let searchReq = req.clone({
        // headers: req.headers.set('api-key', apiService.apiKey),
        setHeaders: { 'api-key': apiService.apiKey, 'Content-Type': 'multipart form-data' },
      });

      return next(searchReq);
    } else {
      let searchReq = req.clone({
      // headers: req.headers.set('api-key', apiService.apiKey),
      setHeaders: { 'api-key': apiService.apiKey },
    });

    return next(searchReq);
    }

    
  }

  return next(req);
};
