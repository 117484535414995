import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PagerSettings } from '@progress/kendo-angular-grid/pager/pager-settings';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ResultCardComponent } from './result-card/result-card.component';
import { IResultItem } from '../../../interfaces/i-result-item';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { Event } from '@angular/router';
import { SVGIcon, fileExcelIcon, arrowRotateCwSmallIcon } from '@progress/kendo-svg-icons';
import { BreakpointService } from '../../../services/breakpoint.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-search-result-list',
  standalone: true,
  imports: [
    ListViewModule,
    ResultCardComponent,
    TextBoxModule,
    ButtonsModule,
    ExcelExportModule,
    MatIcon,
  ],
  templateUrl: './search-result-list.component.html',
  styleUrl: './search-result-list.component.scss'
})
export class SearchResultListComponent implements OnInit {
  @Input() searchList: IResultItem[] = [];
  @Input() isFeedPage = false;
  @Output() doRefreshFeed = new EventEmitter<boolean>();
  @ViewChild(DataBindingDirective) dataBinding?: DataBindingDirective;

  isSmallViewPort = false;
  listData: IResultItem[] = [];

  pagerSettings: PagerSettings = {
    previousNext: false,
    pageSizes: false,
    buttonCount: 9,
  };
  pageSize = 5;

  protected readonly fileExcelIcon: SVGIcon = fileExcelIcon;
  protected readonly arrowRotateCwSmallIcon: SVGIcon = arrowRotateCwSmallIcon;

  #breakpointService = inject(BreakpointService);

  constructor() {
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();
  }

  ngOnInit(): void {
    this.listData = this.searchList.slice();
    // this.loadMore();
  }

  onFilter(value: Event): void {
    if (value.toString() !== '') {
      this.listData = process(this.listData, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'content',
              operator: 'contains',
              value: value,
            },
            {
              field: 'unifiedContent',
              operator: 'contains',
              value: value,
            },
            {
              field: 'dataCard',
              operator: 'contains',
              value: value,
            },
            {
              field: 'metadataStorageName',
              operator: 'contains',
              value: value,
            },
          ],
        },
      }).data;
    } else {
      this.listData = this.searchList.slice();
    }

    // this.dataBinding!.skip = 0;
  }

  refreshFeed() {
    this.doRefreshFeed.emit(true);
  }

  // loadMore() {
  //   console.log('loadMore');
  //   const next = this.listData.length;
  //   this.listData = [...this.listData, ...this.searchList.slice(next, next + this.pageSize)];
  // }
}

