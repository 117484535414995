import { Component, Input, OnInit } from '@angular/core';
import { IResultItem } from '../../../../../../interfaces/i-result-item';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IPost } from '../../../../../../interfaces/i-post';
import { RouterLink } from '@angular/router';
import { DataCardLabel, RoutePath } from '../../../../../../enums/api.enum';
import { ISensitivityLabel } from '../../../../../../interfaces/i-sensitivity-label';
import { SENSITIVITY_LABELS } from '../../../../../../constants/sensitivity.constants';
import { FILE_DOWNLOAD_BASE_URL, FILE_DOWNLOAD_KEY } from '../../../../../../constants/api.constant';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'card-post',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIconModule,
    MatChipsModule
  ],
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss'
})
export class PostCardComponent implements OnInit {
  @Input() item!: IResultItem;
  post!: Partial<IPost>;
  postRoute = RoutePath.MyPosts;
  sensitivityLabel: ISensitivityLabel = { id: 0, label: 'TBD'};
  // An array of attached files that the user has uploaded
  attachedFiles: {name: string, path: string}[] = [];
  dataCardLabel = DataCardLabel;

  ngOnInit(): void {
    const post = JSON.parse(this.item.unifiedContent);
    this.post = {
      postId: post['id'],
      userId: post['userid'],
      post: post['post'],
      title: post['title'],
      files: post['files']
    };

    if (this.item.sensitivityLabel) {
      this.sensitivityLabel = SENSITIVITY_LABELS.find(label => label.id === +this.item.sensitivityLabel!)!;
    }
    
    if (this.post.files) {
      let files = this.post.files.split(',');
      for (const file of files) {
        this.attachedFiles.push({name: file, path: `${FILE_DOWNLOAD_BASE_URL}/${file}?${FILE_DOWNLOAD_KEY}`});
      }
    }

    
  }
}
