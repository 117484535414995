<div class="auth-wrapper">
  <div class="auth-content">
    <div class="header">
      <h1><img ngSrc="./assets/images/CGI_logo_color_rgb.svg" alt="Logo" height="70" width="105" priority> Social</h1>
    </div>
    <button mat-raised-button (click)="onSignin()" class="btn-signin" type="button">Sign in</button>
    <!-- <button mat-raised-button (click)="onSignOut()" class="btn-signin" type="button">Sign out</button> -->
    
    <!--    <form [formGroup]="authForm">-->
    <!--      @if (showErrorMessage) {-->
    <!--        <div class="error-message">-->
    <!--          <div>Incorrect credentials.</div>-->
    <!--          <kendo-svgicon [icon]="exclamationCircleIcon" size="large"></kendo-svgicon>-->
    <!--        </div>-->
    <!--      }-->

    <!--      <div class="input-wrapper">-->
    <!--      <kendo-formfield>-->
    <!--        <kendo-label-->
    <!--          labelCssClass="k-form-label"-->
    <!--          [for]="username"-->
    <!--          text="Username"></kendo-label>-->
    <!--        <kendo-textbox-->
    <!--          formControlName="username"-->
    <!--          #username-->
    <!--          showErrorIcon="initial"-->
    <!--          required></kendo-textbox>-->

    <!--        <kendo-formerror>Username is required</kendo-formerror>-->
    <!--      </kendo-formfield>-->
    <!--      </div>-->

    <!--      <kendo-formfield>-->
    <!--        <kendo-label-->
    <!--          labelCssClass="k-form-label"-->
    <!--          [for]="password"-->
    <!--          text="Password"></kendo-label>-->
    <!--        <kendo-textbox-->
    <!--          type="password"-->
    <!--          formControlName="password"-->
    <!--          #password-->
    <!--          showErrorIcon="initial"-->
    <!--          required></kendo-textbox>-->

    <!--        <kendo-formerror>Password is required</kendo-formerror>-->
    <!--      </kendo-formfield>-->

    <!--      <div class="k-form-buttons btn-submit" kendoRippleContainer>-->
    <!--        <button kendoButton themeColor="primary" (click)="onSubmitAuth()">-->
    <!--          Sign in-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </form>-->
  </div>
</div>