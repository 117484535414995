<div id="topContainer" class="top" [ngClass]="{'top-fs': isFullScreen()}">
    <mat-sidenav-container>
        <mat-sidenav #chatSessions mode="over" position="end" class="sidenav-container">
            <div class="nav-label">Sessions</div>
            <mat-nav-list class="nav-list">
                <a mat-list-item [routerLink]="['/', 'ai-assist', 'aaaaaaaaaaa']">
                    <div class="list-content">
                        <div class="menu-item-text">This is a long session name for an example to show in the menu</div>
                        <div>
                            <button mat-icon-button aria-label="Edit" class="btn-nav-action">
                                <mat-icon  class="material-symbols-outlined"
                                    fontIcon="edit" /></button>
                            <button mat-icon-button aria-label="Delete" class="btn-nav-action">
                                <mat-icon class="material-symbols-outlined"
                                    fontIcon="delete" /></button>
                        </div>
                    </div>
                </a>
                <a mat-list-item [routerLink]="['/', 'ai-assist', 'bbbbbbbbb']">
                    <div class="list-content">
                        <span class="menu-item-text">16-07-2024 3:24</span>
                        <div>
                            <button mat-icon-button aria-label="Edit" class="btn-nav-action">
                                <mat-icon  class="material-symbols-outlined"
                                    fontIcon="edit" /></button>
                            <button mat-icon-button aria-label="Delete" class="btn-nav-action">
                                <mat-icon class="material-symbols-outlined"
                                    fontIcon="delete" /></button>
                        </div>
                    </div>
                </a>
                <a mat-list-item [routerLink]="['/', 'ai-assist', 'ccccccccccccc']">
                    <div class="list-content">
                        <span class="menu-item-text">30-06-2024 2:24</span>
                        <div>
                            <button mat-icon-button aria-label="Edit" class="btn-nav-action">
                                <mat-icon  class="material-symbols-outlined"
                                    fontIcon="edit" /></button>
                            <button mat-icon-button aria-label="Delete" class="btn-nav-action">
                                <mat-icon class="material-symbols-outlined"
                                    fontIcon="delete" /></button>
                        </div>
                    </div>
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content class="content-container">
            <div #chat class="chat-container" [ngClass]="{'chat-cont-fs': isFullScreen()}">
                <!-- <div id="topContainer" class="top" [ngClass]="{'top-fs': isFullScreen()}"> -->
                <div class="chat-header">
                    <div class="btn-group">
                        <button mat-button (click)="chatSessions.toggle()" #tooltip="matTooltip"
                            matTooltipPosition="above" matTooltip="Select a session" aria-label="Select a session">
                            <mat-icon class="material-symbols-outlined">menu</mat-icon>
                        </button>

                        <button mat-button
                            (click)="toggleFullScreen()" #tooltip="matTooltip" matTooltipPosition="above"
                            matTooltip="Fullscreen" aria-label="Fullscreen">
                            @if (!isFullScreen()) {
                            <mat-icon class="material-symbols-outlined">open_in_full</mat-icon>
                            } @else {
                            <mat-icon class="material-symbols-outlined">close_fullscreen</mat-icon>
                            }
                        </button>

                        <!-- <button mat-button #btnFs id="btnFs" [ngClass]="{'btn-hide': isFullScreen()}"
                            (click)="fullScreen()" #tooltip="matTooltip" matTooltipPosition="above"
                            matTooltip="Fullscreen" aria-label="Fullscreen">
                            <mat-icon class="material-symbols-outlined">open_in_full</mat-icon>
                        </button> -->
                        <!-- <button mat-button #btnExitFs id="btnExitFs" [ngClass]="{'btn-hide': !isFullScreen()}"
                            (click)="exitFullScreen()" #tooltip="matTooltip" matTooltipPosition="below"
                            matTooltip="Exit fullscreen" aria-label="Exit fullscreen">
                            <mat-icon class="material-symbols-outlined">close_fullscreen</mat-icon>
                        </button> -->
                    </div>
                </div>

                <div class="chat-content" [ngClass]="{'chat-content-fs': isFullScreen()}">
                    @for (content of chatHistory(); track content) {
                        <div class="bubble" [ngClass]="{'right': content.role === chatRole.user}">
                            @if (content.id === '0') {
                                <div class="k-typing-indicator"><span></span><span></span><span></span>
                                </div>
                            } @else {
                                <p>{{ content.content }}</p>

                            <div class="btn-grp-assistant-resp">
                                @if (content.role !== chatRole.user) {
                                <button mat-button type="button" aria-label="Information" class="btn-chat">
                                    <mat-icon class="material-symbols-outlined" fontIcon="info" />
                                </button>
                                }
                                <button mat-button type="button" aria-label="Copy response" class="btn-chat">
                                    <mat-icon class="material-symbols-outlined" fontIcon="content_copy" />
                                </button>
                            </div>
                            }
                            
                        </div>
                    }
                </div>

                <div class="chat-footer">
                    <form [formGroup]="chatForm" (ngSubmit)="onSubmitChat()">
                        <mat-form-field class="input-question" id="chat-input">
                            <button type="button" matPrefix mat-icon-button color="primary" aria-label="Export chat"
                                class="btn-chat-prefix" title="Export chat">
                                <mat-icon class="material-symbols-outlined icon-sm" aria-hidden="true"
                                    fontIcon="export_notes" />
                            </button>
                            <button type="button" matPrefix mat-icon-button color="primary" aria-label="Add attachment"
                                class="btn-chat-prefix">
                                <mat-icon class="material-symbols-outlined icon-sm" aria-hidden="true"
                                    fontIcon="attach_file" />
                            </button>
                            <mat-label>Ask me anything...</mat-label>
                            <input matInput formControlName="content" placeholder="">
                            <button type="button" matSuffix mat-icon-button color="primary" aria-label="Clear chat"
                                class="btn-chat-prefix">
                                <mat-icon class="material-symbols-outlined btn-clear" fontIcon="close" />
                            </button>
                            <mat-icon matSuffix class="material-symbols-outlined icon-divider" aria-hidden="true"
                                fontIcon="minimize" />

                            <button type="button" matSuffix mat-icon-button
                                aria-label="Use microphone to chat" class="btn-chat-prefix">
                                <mat-icon class="material-symbols-outlined btn-mic" aria-hidden="true" fontIcon="microphone" />
                            </button>
                            <button type="submit" matSuffix mat-icon-button 
                                aria-label="Submit question">
                                <mat-icon class="material-symbols-outlined btn-send" fontIcon="send" aria-hidden="true" />
                            </button>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </mat-sidenav-content>
        <!-- </div> -->
    </mat-sidenav-container>
</div>
