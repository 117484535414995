import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error) => {
      // Add any specific error handling here
      if ([401, 403].includes(error.status)) {
        console.log('Unauthorized request');
      }

      if ([404].includes(error.status)) {
        console.log('Page not found');
      }

      console.error(error.message);

      return throwError(() => error);
    })
  );
};
