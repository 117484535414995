<div class="container">
    <kendo-chat
      class="chat"
      messageBoxType="textarea"
      [messages]="(feed | async)!"
      [user]="user"
      (sendMessage)="sendMessage($event)"
    >
    </kendo-chat>

    <!-- <kendo-aiprompt
      class="prompt"
      (promptRequest)="onPromptRequest($event)"
      (commandExecute)="onCommandExecute($event)"
      [promptOutputs]="promptOutputs"
      [promptCommands]="commands"
      [promptSuggestions]="suggestions"
      [(activeView)]="activeView"
    >
      <kendo-aiprompt-prompt-view></kendo-aiprompt-prompt-view>
      <kendo-aiprompt-output-view></kendo-aiprompt-output-view>
      <kendo-aiprompt-command-view></kendo-aiprompt-command-view>
    </kendo-aiprompt> -->
  </div>
