import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MyPostsService } from "../services/my-posts.service";
import { BreakpointService } from '../../../services/breakpoint.service';

@Component({
  selector: 'app-media-share',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './media-share.component.html',
  styleUrl: './media-share.component.scss'
})
export class MediaShareComponent {
  @ViewChild('recordedVideo') recordedVideo!: ElementRef;
  @ViewChild('mediaViewer') mediaViewer!: ElementRef;
  // @ViewChild('resetButton') resetButton!: ElementRef;
  @ViewChild('mediaViewerContainer') mediaViewerContainer!: ElementRef;
  @Output() isRecordingEvent: EventEmitter<any> = new EventEmitter();
  @Output() showMediaShareEvent: EventEmitter<boolean> = new EventEmitter();

  mediaRecorder!: MediaRecorder;
  recordedChunks: Blob[] = [];
  showIsRecording = false;
  screenCaptureBlob: Blob | null = null;

  breakpointService = inject(BreakpointService);

  #myPostsService = inject(MyPostsService);

  /**
   * Starts the screen share recording process.  This opens up the browser's native UI to share a screen.
   */
  startRecording() {
    const displayMediaOptions = {
      video: true,
      audio: { echoCancellation: true, noiseSuppression: true, sampleRate: 44100 }
    };
    this.isRecordingEvent.emit(true);
    navigator.mediaDevices.getDisplayMedia(displayMediaOptions).then((screenStream) => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((audioStream) => {
        const videoTracks = screenStream.getVideoTracks();
        const audioTracks = audioStream.getAudioTracks();
        const mediaStream = new MediaStream([...videoTracks, ...audioTracks]);
        this.recordedVideo.nativeElement.srcObject = mediaStream;
        this.mediaRecorder = new MediaRecorder(mediaStream);
        this.mediaRecorder.ondataavailable = (event) => {
          this.recordedChunks.push(event.data);
        };
        this.mediaRecorder.onstop = () => {
          const recordedBlob = new Blob(this.recordedChunks, { type: 'video/mp4' });
          this.screenCaptureBlob = recordedBlob;
          this.#myPostsService.mediaFile = recordedBlob;
          this.mediaViewer.nativeElement.src = URL.createObjectURL(recordedBlob);
          this.mediaViewerContainer.nativeElement.style.display = 'block';
          this.recordedChunks = [];
        };
        this.mediaRecorder.start();
        this.showIsRecording = true;
      }).catch((error) => {
        console.error('Error accessing audio:', error);
      });
    }).catch((error) => {
      console.error('Error accessing media devices:', error);
    });
  }

  /**
   * Stops the screen share recording.
   */
  stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      const videoTracks = this.recordedVideo.nativeElement.srcObject.getTracks();
      videoTracks.forEach((track: MediaStreamTrack) => track.stop());
      this.recordedVideo.nativeElement.srcObject = null;

      this.mediaViewerContainer.nativeElement.style.display = 'none';
      this.isRecordingEvent.emit(false);
    }
    this.showIsRecording = false;
  }

  /**
   * Resets the screen share recording.
   */
  resetRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      const videoTracks = this.recordedVideo.nativeElement.srcObject.getTracks();
      videoTracks.forEach((track: MediaStreamTrack) => track.stop());

      this.recordedVideo.nativeElement.srcObject = null;
      this.mediaViewer.nativeElement.src = '';
      this.mediaViewerContainer.nativeElement.style.display = 'none';
    }
  }

  /**
   * Downloads the created screen share file to the user's device.
   */
  downloadScreenCapture() {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(this.screenCaptureBlob!);
    link.download = 'recorded-video.mp4';
    link.click();
  }

  /**
   * Clears the screen capture video.  Deletes it in memory and removes the video display component.
   */
  clearScreenCapture() {
    this.screenCaptureBlob = null;
    this.recordedVideo.nativeElement.srcObject = null;
    this.mediaViewer.nativeElement.src = '';
    this.mediaViewerContainer.nativeElement.style.display = 'none';
    this.#myPostsService.mediaFile = undefined;
  }

  /**
   * Cancels the screen share.  Sends an event to cancel the screen share.  The event listener should hide this component from the UI.
   */
  cancelScreenCapture() {
    // First clear out any screen share that the use has made.
    this.clearScreenCapture();
    this.showMediaShareEvent.emit(false);
  }
}
