import { Component, effect, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultTableComponent } from './search-result-table/search-result-table.component';
import { SearchResultListComponent } from './search-result-list/search-result-list.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { Subscription } from 'rxjs';
import { IResultItem } from '../../interfaces/i-result-item';
import { SearchService } from './services/search.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { SpeechService } from '../../services/speech.service';
import { MatIconModule } from '@angular/material/icon';
import { ShareItemComponent } from '../share-item/share-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { IconsModule } from '@progress/kendo-angular-icons';
import { AlertEnum } from '../../shared/alert/alert.enum';
import { AlertService } from '../../shared/alert/alert.service';
import { SearchRequestBody } from '../../models/search-request-body';
import { ShareItemService } from '../share-item/services/share-item.service';
import { MyPostsService } from '../my-posts/services/my-posts.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { AddPostDialogComponent } from '../my-posts/add-post-dialog/add-post-dialog.component';

@Component({
  selector: 'app-unified-search',
  standalone: true,
  imports: [
    CommonModule,
    SearchResultTableComponent,
    SearchResultListComponent,
    ReactiveFormsModule,
    FormFieldModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    SpinnerComponent,
    MatIconModule,
    ShareItemComponent,
    MatButtonModule,
    MatDividerModule,
    IconsModule,
    AddPostDialogComponent
  ],
  templateUrl: './unified-search.component.html',
  styleUrl: './unified-search.component.scss'
})
export class UnifiedSearchComponent implements OnDestroy {
  searchForm: FormGroup;
  searchResults: IResultItem[] = [];
  isSmallViewPort = false;
  openShareLinkDialog = false;
  openPostDialog = false;
  noResultsFound = false;

  protected speechActive = false;

  #spinnerService = inject(SpinnerService);
  #formBuilder = inject(FormBuilder);
  #speechService = inject(SpeechService);
  #searchService = inject(SearchService);
  #shareItemService = inject(ShareItemService);
  #alertService = inject(AlertService);
  #myPostsService = inject(MyPostsService);
  #breakpointService = inject(BreakpointService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();
    this.searchForm = this.#formBuilder.group({
      search: new FormControl({value: '', disabled: false}, [Validators.required])
    });

    effect(() => {
      if (this.#searchService.latestSearchResults().length > 0) {
        this.searchResults = this.#searchService.latestSearchResults();
      }
      this.openShareLinkDialog = this.#shareItemService.openShareLinkDialog();
      this.openPostDialog = this.#myPostsService.openPostDialog();
    });
  }

  ngOnDestroy(): void {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSubmitSearch() {
    this.#spinnerService.show();
    this.noResultsFound = false;

    if (this.searchForm.valid) {
      // Clear previous search
      this.searchResults = [];
      
      // Generate the search request body
      const requestBody = new SearchRequestBody(this.searchForm.value['search']);

      const searchSubscription = this.#searchService.fetchSearchResults(requestBody).subscribe({
        next: resp => {
          this.searchResults = this.#searchService.manageSearchResults(resp);

          // Save search results in state.  Used when user moves away from the search page then returns.  This will populate the search so they don't loose it.
          this.#searchService.latestSearchResults.set(this.searchResults);

          this.noResultsFound = (this.searchResults.length === 0);

          this.#spinnerService.hide();
        },
        error: (err) => {
          this.#alertService.showAlert({
            message: 'An error occurred getting the search results.',
            type: AlertEnum.error,
          });

          this.#spinnerService.hide();
        }
      });

      this.#subscriptions.push(searchSubscription);
    } else {
      this.searchForm.markAllAsTouched();
      this.#spinnerService.hide();
      return;
    }
  }

  async speechInput() {
    this.searchForm.get('search')?.setValue('');
    this.searchForm.get('search')?.disable();
    this.speechActive = true;

    const prompt = await this.#speechService.listen(navigator.language);
    this.searchForm.get('search')?.setValue(prompt);
    this.searchForm.get('search')?.enable();

    this.speechActive = false;
  }

  clearSearch() {
    this.searchForm.markAsUntouched();
    this.searchForm.reset('');
    this.searchResults = [];
    this.#searchService.latestSearchResults.set([]);
  }
}
