<div class="search-top">
  <div class="search-form">
    <h1>Search</h1>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()">
      <div class="input-btn-group">
        <kendo-formfield showHints="always" style="width: 100%;">
          <kendo-label style="width: 100%;">
            <kendo-textbox class="search-input"
                           [ngClass]="{'error': !searchForm.valid && searchForm.touched}"
                           placeholder="Search..."
                           formControlName="search"
                           required
                           rounded="full"
                           fillMode="outline"
                           showErrorIcon="initial">

              <ng-template kendoTextBoxSuffixTemplate [showSeparator]="true">
                <button class="btn-clear-search"
                        [ngClass]="{'btn-show': searchResults.length > 0, 'btn-hide': searchResults.length === 0 }"
                        kendoButton
                        rounded="none"
                        (click)="clearSearch()"
                        type="button"
                        aria-label="Clear current search">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Clear search results"
                    fontIcon="close"/>
                </button>

                <kendo-textbox-separator></kendo-textbox-separator>

                <button class="btn-speech" kendoButton
                        rounded="none"
                        (click)="speechInput()"
                        type="button"
                        aria-label="Use microphone to search">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Microphone"
                    fontIcon="microphone"/>
                </button>

                <button kendoButton
                        class="btn-search"
                        type="submit"
                        title="Search"
                        rounded="small"
                        fillMode="solid"
                        [primary]="true">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Search"
                    fontIcon="search"/>
                </button>
              </ng-template>
            </kendo-textbox>
          </kendo-label>
          <kendo-formerror>Search text is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </form>
  </div>
</div>
<div [ngClass]="{'search-container-sm': isSmallViewPort}">

  @if (searchResults.length > 0) {
    <app-search-result-list [searchList]="searchResults"></app-search-result-list>
  }
  @if (noResultsFound) {
    <p class="empty">No search results found.</p>
  }
</div>

@if (openShareLinkDialog) {
  <app-share-item></app-share-item>
}
@if (openPostDialog) {
  <add-post-dialog></add-post-dialog>
}


