import { UserSetting } from '../enums/api.enum';
import { logoutIcon, userIcon } from '@progress/kendo-svg-icons';

export const USER_SETTINGS = [
  {
    text: UserSetting.Profile,
    svgIcon: userIcon
  },
  {
    text: UserSetting.SignOut,
    svgIcon: logoutIcon
  }
];

